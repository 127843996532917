import axios from 'axios';
import { getCsrfToken } from '../utils/cookies';

const DOMAIN = window.location.origin;

const axiosInstance = axios.create({
  baseURL: `${DOMAIN}/api`,
  withCredentials: true,
});

axiosInstance.interceptors.request.use(
  config => {
    // Get the CSRF token from the cookie and add it to the request header
    const csrftoken = getCsrfToken();
    if (csrftoken) {
      config.headers['X-CSRFToken'] = csrftoken;
    }
    return config;
  },
  error => Promise.reject(error)
);

export default axiosInstance;
