import React from 'react';
import { Box } from '@mui/material';

import CalendarScheduler from '../../components/CalendarScheduler/CalendarScheduler';
import StoresPage from '../StoresPage/StoresPage';
import { getIsSuperuser } from '../../utils/cookies';

const HomePage = ({ hasLoggedIn }) => {
  if (!hasLoggedIn) {
    return null;
  }
  const isSuperuser = getIsSuperuser();
  const HomePageComponent = isSuperuser ? StoresPage : CalendarScheduler;
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', padding: '16px' }}>
      <HomePageComponent />
    </Box>
  );
};

export default HomePage;
