import React, { useState } from 'react';
import {
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper,
  IconButton, TablePagination
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { converToLocal } from "../../utils/dateUtil";
import { addHyphenToPhoneNumber } from '../../utils/commonUtil';
import { Link } from 'react-router-dom';

const StoresTable = ({ stores, totalCount, rowsPerPage, onStoreEdit, onStoreDelete, onPageChange, onPageSizeChange }) => {
  const [page, setPage] = useState(0);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    onPageChange(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    onPageSizeChange(event.target.value, 10);
  };

  return (
    <TableContainer component={Paper}>
      <Table size='small'>
        <TableHead sx={{
          '& .MuiTableRow-root': {  // Targets the TableRow within the TableHead
            backgroundColor: '#354B5E',
          },
          '& .MuiTableCell-head': {  // Optionally, target individual cells if needed
            color: 'white',  // This will make the text color white for better contrast
          }
        }}>
          <TableRow sx={{ '& > *': { padding: '8px 16px' } }}>
            <TableCell>Store Name</TableCell>
            <TableCell>Email Address</TableCell>
            <TableCell>Phone Number</TableCell>
            <TableCell>Default Appointment Duration(mins)</TableCell>
            <TableCell>Message Count</TableCell>
            <TableCell>First Name</TableCell>
            <TableCell>Last Name</TableCell>
            <TableCell>Date Joined</TableCell>
            <TableCell>Last Login</TableCell>
            <TableCell>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody sx={{
          '& .MuiTableRow-root:nth-of-type(odd)': {
            backgroundColor: 'white',
          },
          '& .MuiTableRow-root:nth-of-type(even)': {
            backgroundColor: '#F5F5F5',
          },
        }}>
          {stores.map((row) => (
            <TableRow key={row.user.id} sx={{ '& > *': { padding: '4px 8px' } }}>
              <TableCell>{row.user.username}</TableCell>
              <TableCell>{row.user.email}</TableCell>
              <TableCell>{addHyphenToPhoneNumber(row.phone)}</TableCell>
              <TableCell>{row.default_appointment_duration}</TableCell>
              <TableCell>
                <Link to={`/messageCounts/${row.user.id}`}>Message Count</Link>
              </TableCell>
              <TableCell>{row.user.first_name}</TableCell>
              <TableCell>{row.user.last_name}</TableCell>
              <TableCell>{converToLocal(row.user.date_joined)}</TableCell>
              <TableCell>{converToLocal(row.user.last_login)}</TableCell>
              <TableCell>
                <IconButton onClick={() => onStoreEdit(row)}>
                  <EditIcon />
                </IconButton>
                <IconButton onClick={() => onStoreDelete(row.user.id)}>
                  <DeleteIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[20, 40, 60]}
        rowsPerPage={rowsPerPage}
        page={page}
        component="div"
        count={totalCount}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </TableContainer>
  );
}

export default StoresTable;
