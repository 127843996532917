import React, { useEffect, useState } from 'react';
import {Typography, Select, MenuItem, Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField, Box, Grid, Alert } from '@mui/material';

import AdminsTable from '../../components/AdminsTable/AdminsTable';
import Spinner from '../../components/Spinner/Spinner';

import {createAdmin, listAdmins, updateAdmin, deleteAdmin} from '../../apis/adminsApis'
import { getConfig} from '../../apis/accountApis'
import { addHyphenToPhoneNumber } from '../../utils/commonUtil';
import { errorMessage } from '../../messages/messages';

const AdminsPage = () => {
  const [admins, setAdmins] = useState([]);
  const [spinnerOpen, setSpinnerOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(20);
  const [totalCount, setTotalCount] = useState(0);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [editAdminDialog, setEditAdminDialog] = useState('');
  const [config, setConfig] = useState([]);
  const [error, setError] = useState('');

  const [admin, setAdmin] = useState({
    id: '',
    username: '',
    password: '',
    reEnterPassword: '',
    email: '',
    phone: '',
    first_name: '',
    last_name: '',
  });


  useEffect(() => {
    fetchPaginatedAdmins(currentPage, pageSize);
  }, [currentPage, pageSize]);

  const fetchPaginatedAdmins =  async (page = 0, pageSize) => {
    setSpinnerOpen(true);
    try {
      const res = await listAdmins(page, pageSize);
      setAdmins([...res.data.users]);
      setTotalCount(res.data.total);
    } catch(e) {

    } finally {
      setSpinnerOpen(false);
    }
  };

  useEffect(() => {
    fetchConfig();
  }, []);

  const fetchConfig =  async () => {
    setSpinnerOpen(false);
    try {
      const res = await getConfig();      
      setConfig({
        config: res.data['config'],
      });
    } catch(e) {

    } finally {
      setSpinnerOpen(false);
    }
  };

  const onPageChangeHandler = (page) => {
    setCurrentPage(page);
  };

  const onPageSizeChangeHandler = (pageSize) => {
    setPageSize(pageSize);
  }

  const onAdminCreate = () => {
    setEditAdminDialog('CREATE')
    setDialogOpen(true);
    setAdmin({
      id: '',
      username: '',
      email: '',
      firstName: '',
      lastName: '',
      phone: '',
    });
  };

  const onAdminEdit = (admin) => {
    setEditAdminDialog('EDIT');
    setDialogOpen(true);
    setAdmin({
      id: admin.user['id'],
      username: admin.user['username'],
      email: admin.user['email'],
      first_name: admin.user['first_name'],
      last_name: admin.user['last_name'],
      phone: admin.phone,
    });
  };

  const onAdminDelete = (adminId) => {
    setEditAdminDialog('DELETE');
    setDialogOpen(true);
    setAdmin({
      id: adminId,
    });
  };


  const handleCreateAdmin = async () => {
    if (admin.password !== admin.reEnterPassword) {
        setError(errorMessage.PasswordNotMatch);
        return;
      }
    setSpinnerOpen(true);
    try {
      const res = await createAdmin(admin);
      const newAdmin = res.data.admin;
      const updatedAdmins = [...admins, newAdmin];
      setAdmins(updatedAdmins);
    } catch (e) {
      // setIsFlashOpen(true);
      // setFlashMessage(e.response.data.error.message);
    } finally {
      setSpinnerOpen(false);
      setDialogOpen(false);
    }
  };



  const handleUpdateAdmin = async () => {
    setSpinnerOpen(true);
    try {
      const res = await updateAdmin(admin);

      const updatedAdmin = res.data;
      setAdmins(prevAdmins => {
        // Create a new array based on the previous admins
        const updatedAdmins = [...prevAdmins];
        const index = updatedAdmins.findIndex(e => e.user['id'] === updatedAdmin.user['id']);
        if (index !== -1) {
          updatedAdmins[index] = updatedAdmin;

        }

        return updatedAdmins;
      }
      );
    } catch (e) {
      // setIsFlashOpen(true);
      // setFlashMessage(e.response.data.error.message);
    } finally {
      setSpinnerOpen(false);
      setDialogOpen(false);
    }
  };

  const handleDeleteAdmin = async () => {
    setSpinnerOpen(true);
    try {
      const res = await deleteAdmin(admin);      
      const updatedAdmins = admins.filter(c => c.id != admin.id);
      setAdmins(updatedAdmins);
    } catch (e) {
      // setIsFlashOpen(true);
      // setFlashMessage(e.response.data.error.message);
    } finally {
      setDialogOpen(false);
      setSpinnerOpen(false);
    }
  };

  const handleAdminChange = (e) => {
    const { name, value } = e.target;
    setAdmin((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  
  const renderDialogContentCreate = (
    <Box component='form' sx={{ m: 2 }}>
      {
        <div>
          <TextField
            label='Username'
            variant='outlined'
            fullWidth
            margin='normal'
            name='username'
            required
            value={admin.username}
            onChange={handleAdminChange}
          />
          
          <TextField
            label='Password'
            variant='outlined'
            fullWidth
            margin='normal'
            name='password'
            type='password'
            required
            value={admin.password}
            onChange={handleAdminChange}
          />

          <TextField
            label='Re-enter Password'
            variant='outlined'
            fullWidth
            margin='normal'
            name='reEnterPassword'
            type='password'
            required
            value={admin.reEnterPassword}
            onChange={handleAdminChange}
          />

          <TextField
            label='First Name'
            variant='outlined'
            fullWidth
            margin='normal'
            name='firstName'
            value={admin.first_name}
            onChange={handleAdminChange}
            InputProps={{ endAdornment: <Typography variant="caption">(Optional)</Typography> }}
          />
          <TextField
            label='Last Name'
            variant='outlined'
            fullWidth
            margin='normal'
            name='lastName'
            value={admin.last_name}
            onChange={handleAdminChange}
            InputProps={{ endAdornment: <Typography variant="caption">(Optional)</Typography> }}
          />
          <TextField
            label='Phone'
            variant='outlined'
            fullWidth
            margin='normal'
            name='phone'
            value={addHyphenToPhoneNumber(admin.phone)}
            onChange={handleAdminChange}
            InputProps={{ endAdornment: <Typography variant="caption">(Optional)</Typography> }}
          />
          <TextField
            label='Email'
            variant='outlined'
            fullWidth
            margin='normal'
            name='email'
            value={admin.email}
            onChange={handleAdminChange}
            InputProps={{ endAdornment: <Typography variant="caption">(Optional)</Typography> }}
          />
          {error && (
            <Typography color="error" style={{ marginTop: 8 }}>
              {error}
            </Typography>
          )}
        </div>
      }
    </Box>
  );



  const renderCreateDialogComponent = (
    <Dialog
      open={dialogOpen}
      onClose={() => setDialogOpen(false)}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
      fullWidth={true}
    >
      <DialogTitle id='alert-dialog-title'>Create Admin</DialogTitle>
      <DialogContent>
        {renderDialogContentCreate}
      </DialogContent>
      <DialogActions>
        <Button color='primary' variant='contained' onClick={handleCreateAdmin}> Create</Button>
        <Button color='primary' variant='contained' onClick={() => setDialogOpen(false)}> Cancel</Button>
      </DialogActions>
    </Dialog>
  );

  const renderDialogContentUpdate = (
    <Box component='form' sx={{ m: 2 }}>
      {
        <div>
          <TextField
            label='User Name'
            variant='outlined'
            fullWidth
            margin='normal'
            name='username'
            value={admin.username}
            onChange={handleAdminChange}
            disabled
          />
          <TextField
            label='Email'
            variant='outlined'
            fullWidth
            margin='normal'
            name='email'
            value={admin.email}
            onChange={handleAdminChange}
          />
          <TextField
            label='Phone'
            variant='outlined'
            fullWidth
            margin='normal'
            name='phone'
            value={addHyphenToPhoneNumber(admin.phone)}
            onChange={handleAdminChange}
          />
          <TextField
            label='First name'
            variant='outlined'
            fullWidth
            margin='normal'
            name='first_name'
            value={admin.first_name}
            onChange={handleAdminChange}
          />
          <TextField
            label='Last Name'
            variant='outlined'
            fullWidth
            margin='normal'
            name='last_name'
            value={admin.last_name}
            onChange={handleAdminChange}
          />

        </div>
      }
    </Box>
  );



  const renderUpdateDialogComponent = (
    <Dialog
      open={dialogOpen}
      onClose={() => setDialogOpen(false)}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
      fullWidth={true}
    >
      <DialogTitle id='alert-dialog-title'>Update Admin</DialogTitle>
      <DialogContent>
        {renderDialogContentUpdate}
      </DialogContent>
      <DialogActions>
        <Button color='primary' variant='contained' onClick={handleUpdateAdmin}> Save</Button>
        <Button color='primary' variant='contained' onClick={() => setDialogOpen(false)}> Cancel</Button>
      </DialogActions>
    </Dialog>
  );

  const renderDeleteDialogComponent = (
    <Dialog
      open={dialogOpen}
      onClose={() => setDialogOpen(false)}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
      fullWidth={true}
    >
      <DialogTitle id='alert-dialog-title'>Are you sure you want to delete admin</DialogTitle>

      {/* <DialogContent>
          Are you sure you want to delete admin
      </DialogContent> */}
      <DialogActions>
        <Button color='primary' variant='contained' onClick={handleDeleteAdmin}> Delete</Button>
        <Button color='primary' variant='contained' onClick={() => setDialogOpen(false)}> Cancel</Button>
      </DialogActions>
    </Dialog>
  );

  return (
    <Box p={2}>
      <Spinner open={spinnerOpen} />
      <Button style={{ float: 'right' }} color='primary' variant='contained' onClick={onAdminCreate}> Create Admin</Button>

      <AdminsTable
        rowsPerPage={pageSize}
        totalCount={totalCount}
        admins={admins}
        onPageChange={onPageChangeHandler}
        onPageSizeChange={onPageSizeChangeHandler}
        onAdminEdit={onAdminEdit}
        onAdminDelete={onAdminDelete}
      />
      {
          editAdminDialog === 'CREATE' ? renderCreateDialogComponent
          : editAdminDialog === 'EDIT' ? renderUpdateDialogComponent
              : renderDeleteDialogComponent
      }
    </Box>
  );
};

export default AdminsPage;
