import React, { useEffect, useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField, Box} from '@mui/material';

import RulesTable from '../../components/RulesTable/RulesTable';
import Spinner from '../../components/Spinner/Spinner';

import { createRule, listRules, updateRule, activateRule, deactivateRule, deleteRule, getRule } from '../../apis/rulesApis';
import { updateListItemById } from '../../utils/commonUtil';

const RulesPage = () => {
  const [rules, setRules] = useState([]);
  const [spinnerOpen, setSpinnerOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(20);
  const [totalCount, setTotalCount] = useState(0);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [editRuleDialog, setEditRuleDialog] = useState('');

  const [rule, setRule] = useState({
    id: '',
    name: '',
    description: '',
    send_at: '',
    content: '',
    is_active: '',
  });

  useEffect(() => {
    fetchPaginatedRules(currentPage, pageSize);
  }, [currentPage, pageSize]);

  const fetchPaginatedRules =  async (page = 0, pageSize) => {
    setSpinnerOpen(true);
    try {
      const res = await listRules(page, pageSize);
      setRules([...res.data.rules]);
      setTotalCount(res.data.total);
    } catch(e) {

    } finally {
      setSpinnerOpen(false);
    }
  };

  const handleRuleChange = (e) => {
    const { name, value } = e.target;
    setRule((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleCreateRule = async () => {
    setSpinnerOpen(true);
    try {
      rule.is_active = false;
      const res = await createRule(rule);
      const newRule = res.data.rule;
      const updatedRules = [...rules, newRule];
      setRules(updatedRules);
    } catch (e) {
      // setIsFlashOpen(true);
      // setFlashMessage(e.response.data.error.message);
    } finally {
      setSpinnerOpen(false);
      setDialogOpen(false);
    }
  };

  const handleRuleRefreshAfterUpdate = async (ruleId) => {
    const { data: updatedRule }  = await getRule(ruleId);

    setRules(rules => updateListItemById(updatedRule, rules));
  };


  const handleUpdateRule = async () => {
    setSpinnerOpen(true);
    try {

      const res = await updateRule(rule);
      await handleRuleRefreshAfterUpdate(rule.id);
    } catch (e) {
      // setIsFlashOpen(true);
      // setFlashMessage(e.response.data.error.message);
    } finally {
      setSpinnerOpen(false);
      setDialogOpen(false);
    }
  };

  const handleActivateRule = async () => {
    setSpinnerOpen(true);
    try {

      const res = await activateRule(rule);
      handleRuleRefreshAfterUpdate(rule.id);
    } catch (e) {
      // setIsFlashOpen(true);
      // setFlashMessage(e.response.data.error.message);
    } finally {
      setSpinnerOpen(false);
      setDialogOpen(false);
    }
  };

  const handleDeactivateRule = async () => {
    setSpinnerOpen(true);
    try {

      const res = await deactivateRule(rule);
      handleRuleRefreshAfterUpdate(rule.id);
    } catch (e) {
      // setIsFlashOpen(true);
      // setFlashMessage(e.response.data.error.message);
    } finally {
      setSpinnerOpen(false);
      setDialogOpen(false);
    }
  };


  const handleDeleteRule = async () => {
    setSpinnerOpen(true);
    try {
      const res = await deleteRule(rule);
      const updatedRules = rules.filter(c => c.id != rule.id);
      setRules(updatedRules);
    } catch (e) {
      // setIsFlashOpen(true);
      // setFlashMessage(e.response.data.error.message);
    } finally {
      setDialogOpen(false);
      setSpinnerOpen(false);
    }
  };


  const onPageChangeHandler = (page) => {
    setCurrentPage(page);
  };

  const onPageSizeChangeHandler = (pageSize) => {
    setPageSize(pageSize);
  }

  const onRuleCreate = () => {
    setEditRuleDialog('CREATE')
    setDialogOpen(true);
    setRule({
      id: '',
      name: '',
      description: '',
      send_at: '',
      content: '',
      is_active: ''
    });
  };

  const onRuleEdit = (rule) => {

    setEditRuleDialog('EDIT');
    setDialogOpen(true);
    setRule({
      id: rule.id,
      name: rule.name,
      description: rule.description,
      send_at: rule.send_at,
      content: rule.content,
      is_active: rule.is_active,
    });
  };


  const onRuleDelete = (rule) => {
    setEditRuleDialog('DELETE');
    setDialogOpen(true);
    setRule({
      id: rule.id,
    });
  };

  const renderDialogContentCreate = (
    <Box component='form' sx={{ m: 2 }}>
      {
        <div>
          <TextField
            label='Name'
            variant='outlined'
            fullWidth
            margin='normal'
            name='name'
            value={rule.name}
            onChange={handleRuleChange}
          />
          <TextField
            label='Description'
            variant='outlined'
            fullWidth
            margin='normal'
            name='description'
            value={rule.description}
            onChange={handleRuleChange}
          />
          <TextField
            label='Send After(Hours) of Check-In'
            variant='outlined'
            fullWidth
            margin='normal'
            name='send_at'
            value={rule.send_at}
            onChange={handleRuleChange}
          />
          <TextField
            label='Content'
            variant='outlined'
            fullWidth
            margin='normal'
            name='content'
            value={rule.content}
            onChange={handleRuleChange}
          />
        </div>
      }
    </Box>
  );

  const renderCreateDialogComponent = (
    <Dialog
      open={dialogOpen}
      onClose={() => setDialogOpen(false)}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
      fullWidth={true}
    >
      <DialogTitle id='alert-dialog-title'>Create Rule</DialogTitle>
      <DialogContent>
        {renderDialogContentCreate}
      </DialogContent>
      <DialogActions>
        <Button color='primary' variant='contained' onClick={handleCreateRule}> Create</Button>
        <Button color='primary' variant='contained' onClick={() => setDialogOpen(false)}> Cancel</Button>
      </DialogActions>
    </Dialog>
  );


  const renderDialogContentUpdate = (
    <Box component='form' sx={{ m: 2 }}>
      {
        <div>
          <TextField
            label='Name'
            variant='outlined'
            fullWidth
            margin='normal'
            name='name'
            value={rule.name}
            onChange={handleRuleChange}
          />
          <TextField
            label='Description'
            variant='outlined'
            fullWidth
            margin='normal'
            name='description'
            value={rule.description}
            onChange={handleRuleChange}
          />
          <TextField
            label='Send After(Hours) of Check-In'
            variant='outlined'
            fullWidth
            margin='normal'
            name='send_at'
            value={rule.send_at}
            onChange={handleRuleChange}
          />
          <TextField
            disabled
            label='Status'
            variant='outlined'
            fullWidth
            margin='normal'
            name='is_active'
            value={rule.is_active ? "Active" : "Inactive"}
            onChange={handleRuleChange}
          />
          <TextField
            label='Content'
            variant='outlined'
            fullWidth
            margin='normal'
            name='content'
            value={rule.content}
            onChange={handleRuleChange}
          />
        </div>
      }
    </Box>
  );


  const renderUpdateDialogComponent = (
    <Dialog
      open={dialogOpen}
      onClose={() => setDialogOpen(false)}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
      fullWidth={true}
    >
      <DialogTitle id='alert-dialog-title'>Update Rule</DialogTitle>
      <DialogContent>
        {renderDialogContentUpdate}
      </DialogContent>
      <DialogActions>
        <Button color='primary' variant='contained' onClick={handleActivateRule}> Activate</Button>
        <Button color='primary' variant='contained' onClick={handleDeactivateRule}> Deactivate</Button>
        <Button color='primary' variant='contained' onClick={handleUpdateRule}> Save</Button>
        <Button color='primary' variant='contained' onClick={() => setDialogOpen(false)}> Cancel</Button>
      </DialogActions>
    </Dialog>
  );


  const renderDeleteDialogComponent = (
    <Dialog
      open={dialogOpen}
      onClose={() => setDialogOpen(false)}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
      fullWidth={true}
    >
      <DialogTitle id='alert-dialog-title'>Are you sure you want to delete rule</DialogTitle>
      <DialogActions>
        <Button color='primary' variant='contained' onClick={handleDeleteRule}> Delete</Button>
        <Button color='primary' variant='contained' onClick={() => setDialogOpen(false)}> Cancel</Button>
      </DialogActions>
    </Dialog>
  );

  return (
    <Box p={2}>
      <Spinner open={spinnerOpen} />

      {/* <Grid item xs={true} sm={9}> */}
        <Button style={{ float: 'right' }} color='primary' variant='contained' onClick={onRuleCreate}> Create Rule</Button>
      {/* </Grid> */}


      <RulesTable
        rowsPerPage={pageSize}
        totalCount={totalCount}
        rules={rules}
        onPageChange={onPageChangeHandler}
        onPageSizeChange={onPageSizeChangeHandler}
        onRuleEdit={onRuleEdit}
        onRuleDelete={onRuleDelete}
      />
      {
        editRuleDialog === 'CREATE' ? renderCreateDialogComponent
          : editRuleDialog === 'EDIT' ? renderUpdateDialogComponent
            : renderDeleteDialogComponent
      }
    </Box>
  );
};

export default RulesPage;
