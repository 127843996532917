import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';

import { addHyphenToPhoneNumber } from '../../utils/commonUtil';

const AppointmentDialog = ({
  open,
  appointment,
  customer,
  onAppointmentChange,
  onClose,
  onSubmit,
  onCheckIn,
  onUnCheckIn,
  onDelete,
}) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth={true}
    >
      <DialogTitle id="alert-dialog-title">Appointment</DialogTitle>
      <DialogContent>
        <TextField
          label="Title"
          variant="outlined"
          fullWidth
          margin="normal"
          name="title"
          value={appointment.title}
          onChange={onAppointmentChange}
        />
        <TextField
          label="Description"
          variant="outlined"
          fullWidth
          margin="normal"
          name="description"
          value={appointment.description}
          onChange={onAppointmentChange}
        />
        <TextField
          label="First Name"
          variant="outlined"
          fullWidth
          margin="normal"
          name="firstName"
          value={customer?.firstName ?? ''}
          disabled
        />
        <TextField
          label="Last Name"
          variant="outlined"
          fullWidth
          margin="normal"
          name="lastName"
          value={customer?.lastName ?? ''}
          disabled
        />
        <TextField
          label="Phone"
          variant="outlined"
          fullWidth
          margin="normal"
          name="phone"
          value={addHyphenToPhoneNumber(customer?.phone ?? '')}
          disabled
        />
        <TextField
          label="Notes"
          variant="outlined"
          fullWidth
          margin="normal"
          name="notes"
          value={customer?.notes || ''}
          disabled
        />
        <TextField
          label="Start From"
          variant="outlined"
          fullWidth
          margin="normal"
          type="datetime-local"
          name="start"
          value={appointment.start}
          onChange={onAppointmentChange}
          InputLabelProps={{ shrink: true }}
        />
        <TextField
          label="End To"
          variant="outlined"
          fullWidth
          margin="normal"
          type="datetime-local"
          value={appointment.end}
          name="end"
          onChange={onAppointmentChange}
          InputLabelProps={{ shrink: true }}
        />
      </DialogContent>
      <DialogActions>
        <Button color="primary" variant="contained" onClick={onSubmit}>
          Submit
        </Button>
        {appointment.id >= 0 && <Button color="primary" variant="contained" onClick={onCheckIn}>
          Check In
        </Button>}
        {appointment.id >= 0 && <Button color="primary" variant="contained" onClick={onUnCheckIn}>
          Un-Check In
        </Button>}
        {appointment.id >= 0 && <Button color="primary" variant="contained" onClick={onDelete}>
          Delete
        </Button>}
        <Button
          color="secondary"
          variant="contained"
          onClick={onClose}
        >
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  )
};

export default AppointmentDialog;