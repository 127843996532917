import { useNavigate } from 'react-router-dom';

import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";

const NavItem = ({ item, onClose }) => {
  const navigate = useNavigate();
  const { name, route, icon } = item;
  const onButtonClick = () => {
    navigate(route);
    onClose();
  };
  return (
    <ListItem key={name} disablePadding>
      <ListItemButton onClick={onButtonClick}>
        <ListItemIcon>
          {icon}
        </ListItemIcon>
        <ListItemText primary={name} />
      </ListItemButton>
    </ListItem>
  );
};

export default NavItem;