import React, { useState } from 'react';
import { Button, TextField, Paper, Typography, Container } from '@mui/material';

import Spinner from '../Spinner/Spinner';

import { login } from '../../apis/accountApis';

const LoginForm = ({ handleLoginUser, handleLoginErrorMsg }) => {
  const [credentials, setCredentials] = useState({ username: '', password: '' });
  const [spinnerOpen, setSpinnerOpen] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCredentials((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Handle login logic here
    setSpinnerOpen(true);
    try {
      const res = await login(credentials.username, credentials.password);
      handleLoginUser(res.data.user);
    } catch (e) {
      handleLoginErrorMsg(e.response.data.message);
    } finally {
      setSpinnerOpen(false);
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <Spinner open={spinnerOpen} />
      <Paper
        elevation={3}
        style={{ padding: 16, display: 'flex', flexDirection: 'column', alignItems: 'center' }}
      >
        <Typography component="h1" variant="h5">
          Login
        </Typography>
        <form onSubmit={handleSubmit} style={{ width: '100%', marginTop: 8 }}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="username"
            label="Username"
            name="username"
            autoComplete="username"
            autoFocus
            value={credentials.username}
            onChange={handleChange}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            value={credentials.password}
            onChange={handleChange}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            style={{ marginTop: 16 }}
          >
            Sign In
          </Button>
        </form>
      </Paper>
    </Container>
  );
}

export default LoginForm;
