import Box from '@mui/material/Box';
import { Typography } from '@mui/material';

const CustomListEmptyState = () => {
  return (
    <Box sx={{
      width: '100%',
      textAlign: 'center',
      padding: '1rem',
    }}>
      <Typography>No appointments found</Typography>
    </Box>
  );
};

export default CustomListEmptyState;