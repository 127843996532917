import { createTheme } from '@mui/material/styles';


const mordenDeepBlueTheme = createTheme({
  palette: {
    primary: {
      main: '#354B5E',       // Modern Deep Blue
      light: '#4D6A7D',      // Lighter shade
      dark: '#1F3245',       // Darker shade
      contrastText: '#fff',
    },
    secondary: {
      main: '#F5A623',       // Vibrant Gold for CTAs and Highlights
      light: '#FFBF4B',      // Lighter shade of gold
      dark: '#D4881E',       // Darker shade of gold
      contrastText: '#000',
    },
    background: {
      default: '#E8EBEF',   // Neutral cooler background
      paper: '#fff',
    },
    text: {
      primary: '#1F3245',
      secondary: '#4D6A7D',
    },
  },
  typography: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    h1: {
      fontWeight: 600,
    },
    h2: {
      fontWeight: 600,
    },
    h3: {
      fontWeight: 600,
    },
    subtitle1: {
      color: '#4D6A7D',
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
        },
        containedPrimary: {
          color: '#fff', 
          boxShadow: 'none',
        },
        outlinedPrimary: {
          borderColor: '#4D6A7D',
        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          margin: '8px 0',
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              borderColor: '#B5BFC7',
            },
            '&:hover fieldset': {
              borderColor: '#A4B2BE',
            },
            '&.Mui-focused fieldset': {
              borderColor: '#354B5E',
            },
          },
        },
      },
    },
  },
});

export default mordenDeepBlueTheme;
