import axiosInstance from '../axios/axiosInstance';

export const createRule = (rule) => {
  return axiosInstance.post('/rules/', {
    name: rule.name,
    description: rule.description,
    send_at: rule.send_at,
    is_active: rule.is_active,
    content: rule.content,
  });
};

export const listRules = (page = 0, pageSize = 20) => {
  return axiosInstance.get(`/rules/?page=${page + 1}&page_size=${pageSize}`);
};

export const getRule = (ruleId) => {
  return axiosInstance.get(`/rules/${ruleId}/`)
};

export const updateRule = (rule) => {
  return axiosInstance.put(`/rules/${rule.id}/`, {
    name: rule.name,
    description: rule.description,
    send_at: rule.send_at,
    is_active: rule.is_active,
    content: rule.content,
  });
};

export const activateRule = (rule) => {
  return axiosInstance.put(`/rules/activate/`, {
    id: rule.id,
  });
};

export const deactivateRule = (rule) => {
  return axiosInstance.put(`/rules/deactivate/`, {
    id: rule.id,
  });
};



export const deleteRule = (rule) => {
  return axiosInstance.delete(`/rules/${rule.id}/`);
};

