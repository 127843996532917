import axiosInstance from '../axios/axiosInstance';

export const signup = (username, password, email, phone, first_name, last_name) => {
  return axiosInstance.post('/stores/signup/', {
    username,
    password,
    email,
    phone,
    first_name,
    last_name
  });
}

export const login = (username, password) => {
  return axiosInstance.post('/stores/login/', {
    username,
    password,
  });
}

export const signoff = () => {
  return axiosInstance.post('/accounts/logout/');
}

export const getConfig = () => {
  return axiosInstance.get('/stores/config/');
}