import React from 'react';
import { Grid } from '@mui/material';

import SignUpForm from '../../components/SignUpForm/SignUpForm';

const RegisterPage = ({ handleRegister, handleRegisterErrorMsg }) => {
  return (
    <Grid
      container 
      sx={{ minHeight: 'calc(100vh - 256px)' }}  // AppBar height is 64px
      alignItems="center"
      justifyContent="center"
    >
      <SignUpForm handleRegister={handleRegister} handleRegisterErrorMsg={handleRegisterErrorMsg} />
    </Grid>
  );
};

export default RegisterPage;
