/**
 * 
 * @param {*} dateStr like 'Wed Aug 30 2023 14:00:00 GMT-0700 (Pacific Daylight Time)'
 * @returns yyyy-MM-ddThh:mm
 */
export const formatDate = (dateStr) => {
  const date = new Date(dateStr);
  
  const yyyy = date.getFullYear();
  const MM = String(date.getMonth() + 1).padStart(2, '0');  // Months are zero-indexed
  const dd = String(date.getDate()).padStart(2, '0');
  const hh = String(date.getHours()).padStart(2, '0');
  const mm = String(date.getMinutes()).padStart(2, '0');

  return `${yyyy}-${MM}-${dd}T${hh}:${mm}`;
}

export const formatDateAddTime = (dateStr, addByMins) => {
  const date = new Date(dateStr);
  var newDateObj = new Date(date.getTime() + addByMins*60000);

  

  const yyyy = newDateObj.getFullYear();
  const MM = String(newDateObj.getMonth() + 1).padStart(2, '0');  // Months are zero-indexed
  const dd = String(newDateObj.getDate()).padStart(2, '0');
  const hh = String(newDateObj.getHours()).padStart(2, '0');
  const mm = String(newDateObj.getMinutes()).padStart(2, '0');

  return `${yyyy}-${MM}-${dd}T${hh}:${mm}`;
}


/**
 * 
 * @param {*} dateStr like '2023-09-07T11:00'
 */
export const converToUTC = (dateStr) => {
  // Create a Date object from the local date-time string
  const localDateTime = new Date(dateStr);

  // Extract the UTC date-time components
  const utcYear = localDateTime.getUTCFullYear();
  const utcMonth = String(localDateTime.getUTCMonth() + 1).padStart(2, '0');
  const utcDate = String(localDateTime.getUTCDate()).padStart(2, '0');
  const utcHours = String(localDateTime.getUTCHours()).padStart(2, '0');
  const utcMinutes = String(localDateTime.getUTCMinutes()).padStart(2, '0');
  
  // Format the components
  const utcFormatted = `${utcYear}-${utcMonth}-${utcDate}T${utcHours}:${utcMinutes}`;
  return utcFormatted
}

/**
 * 
 * @param {*} isoString like '2023-09-07T11:00'
 */
export const converToLocal = (isoString) => {
  if (isoString === null) {
    return "";
  }

  // Create a new Date object from the ISO string
  const date = new Date(isoString);

  // Check if the date is valid
  if (isNaN(date.getTime())) {
    return "Invalid date";
  }

  // Get the local time zone
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  // Convert the date to local time string with the time zone
  const localTimeString = date.toLocaleString('en-US', { timeZone });
  return localTimeString;
}