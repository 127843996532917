import './App.css';
import React, { useEffect, useState } from 'react';
import { Route, Routes, useNavigate, useLocation } from 'react-router-dom';

import MenuIcon from '@mui/icons-material/Menu';
import AccountCircle from '@mui/icons-material/AccountCircle';
import {
  AppBar, Box, Toolbar, Typography, Button, IconButton, Menu, MenuItem
} from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';

import FlashMessage from '../components/FlashMessage/FlashMessage';
import Spinner from '../components/Spinner/Spinner';
import NavDrawer from '../components/NavDrawer/NavDrawer';

import HomePage from '../pages/HomePage/HomePage';
import LoginPage from '../pages/LoginPage/LoginPage';
import RegisterPage from '../pages/RegisterPage/RegisterPage';
import CustomersPage from '../pages/CustomersPage/CustomersPage';
import RulesPage from '../pages/RulesPage/RulesPage';
import StoresPage from '../pages/StoresPage/StoresPage';
import MessageCountsPage from '../pages/MessageCountsPage/MessageCountsPage';
import MessagesPage from '../pages/MessagesPage/MessagesPage';
import AdminsPage from '../pages/AdminsPage/AdminsPage';


import { infoMessages, successMessages } from '../messages/messages';
import mordenDeepBlueTheme from '../themes/morden-deep-blue';
import { getUserName, getIsSuperuser, setUserName, setIsSuperuser, setMembership, getMembership } from '../utils/cookies';


const theme = mordenDeepBlueTheme;

const App = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [loginUser, setLoginUser] = useState('');
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [isFlashOpen, setIsFlashOpen] = useState(false);
  const [flashMessage, setFlashMessage] = useState('');
  const [spinnerOpen, setSpinnerOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  useEffect(() => {
    const username = getUserName();
    const isSuperuser = getIsSuperuser();
    if (!username) {
      navigate('/login');
      return;
    }

    if (location.pathname === '/login' && username) {
      navigate('/');
    }

    if (location.pathname === '/register' && !isSuperuser) {
      navigate('/');
    }

    setLoginUser(username);
  }, [navigate, location.pathname]);

  const onMenuIconClick = () => {
    if (!loginUser) {
      setFlashMessage(infoMessages.LoginRequiredMsg);
      setIsFlashOpen(true);
      return;
    }

    setDrawerOpen(true);
  };

  const handleLoginUser = (user) => {
    setLoginUser(user.username);
    setUserName(user.username);
    setMembership(user.membership);
    setIsSuperuser(user.is_superuser);
    navigate('/');
  };

  const handleErrorMsg = (errorMsg) => {
    setFlashMessage(errorMsg);
    setIsFlashOpen(true);
  };

  const handleRegister = (name) => {
    setFlashMessage(successMessages.SignUpSuccessMsg);
    setIsFlashOpen(true);
  };

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleSignOff = async (user) => {
    try {
      // await signoff(user.username, user.username);
      setUserName('');
      setMembership('');
      setIsSuperuser('');
      setFlashMessage(successMessages.SignOffSuccessMsg);
      setIsFlashOpen(true);
      setLoginUser('');
      navigate('/login');
    } catch (e) {
      setFlashMessage(e.response.data.detail);
      setIsFlashOpen(true);
    } finally {
      setSpinnerOpen(false);
      setAnchorEl(null);

      setTimeout(() => {
        setIsFlashOpen(false);
      }, 2000);
    }
  };

  const hasLoggedIn = !!loginUser;

  const renderRouteComponent = (
    <Routes>
      <Route path='/' element={<HomePage hasLoggedIn={hasLoggedIn} />} />
      <Route path='/login' element={<LoginPage handleLoginUser={handleLoginUser} handleLoginErrorMsg={handleErrorMsg} />} />
      <Route path='/register' element={<RegisterPage handleRegister={handleRegister} handleRegisterErrorMsg={handleErrorMsg} />} />
      <Route path='/customers' element={<CustomersPage />} />
      <Route path='/rules' element={<RulesPage />} />
      <Route path='/stores' element={<StoresPage />} />
      <Route path='/messages' element={<MessagesPage />} />
      <Route path='/admins' element={<AdminsPage />} />
      <Route path='/messageCounts/:accountId' element={<MessageCountsPage />} />
    </Routes>
  )

  const renderProfileComponent = (
    <div>
      <Button color='inherit'>{loginUser}</Button>
      <IconButton
        size='large'
        aria-label='account of current user'
        aria-controls='menu-appbar'
        aria-haspopup='true'
        onClick={handleMenu}
        color='inherit'
      >
        <AccountCircle />
      </IconButton>
      <Menu
        id='menu-appbar'
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        keepMounted
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
      >
        <MenuItem onClick={handleMenuClose}>Profile</MenuItem>
        <MenuItem onClick={handleMenuClose}>My account</MenuItem>
        <MenuItem onClick={handleSignOff}>Sign Off</MenuItem>
      </Menu>
    </div>
  );

  const renderFlashMessageComponent = (
    <FlashMessage
      message={flashMessage}
      open={isFlashOpen}
      handleClose={() => setIsFlashOpen(false)}
    />
  );

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ flexGrow: 1 }}>
        <AppBar position='static' className='app-bar'>
          <Toolbar>
            <IconButton
              size='large'
              edge='start'
              color='inherit'
              aria-label='menu'
              sx={{ mr: 2 }}
              onClick={onMenuIconClick}
            >
              <MenuIcon />
            </IconButton>
            <Typography variant='h6' component='div' sx={{ flexGrow: 1 }}>
              Customers Notification
            </Typography>

            {!loginUser && <Button color='inherit'>Login</Button>}

            {loginUser && (renderProfileComponent)}
          </Toolbar>
        </AppBar>
      </Box>

      <Spinner open={spinnerOpen} />

      {renderRouteComponent}

      <NavDrawer open={drawerOpen} onClose={() => setDrawerOpen(false)} />

      {renderFlashMessageComponent}
    </ThemeProvider>
  );
};

export default App;
