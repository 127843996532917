import React from 'react';
import { Grid } from '@mui/material';

import LoginForm from '../../components/LoginForm/LoginForm';

const LoginPage = ({ handleLoginUser, handleLoginErrorMsg }) => {
  return (
    <Grid
      container 
      sx={{ minHeight: 'calc(100vh - 256px)' }}  // AppBar height is 64px
      alignItems="center"
      justifyContent="center"
    >
      <LoginForm handleLoginUser={handleLoginUser} handleLoginErrorMsg={handleLoginErrorMsg} />
    </Grid>
  );
};

export default LoginPage;
