import { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import Alert from "@mui/material/Alert";
import { addHyphenToPhoneNumber } from '../../utils/commonUtil';

const INITIAL_CUSTOMER_STATE = {
  firstName: '',
  lastName: '',
  phone: '',
  notes: '',
};

const CreateCustomerDialog = ({ open, onCreate, onClose, phoneNumber }) => {
  const [customer, setCustomer] = useState(INITIAL_CUSTOMER_STATE)
  useEffect(() => {
    if (open) {
      setCustomer({ ...INITIAL_CUSTOMER_STATE, phone: phoneNumber })
    }
  }, [open])
  const alertMessage = `Customer not found with phone# ${addHyphenToPhoneNumber(phoneNumber)}, would you like to create a new customer?`;
  const onCustomerInputChange = (e) => {
    const { name, value } = e.target;
    setCustomer((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  const onCreateCallback = () => {
    onCreate(customer);
  }
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth={true}
    >
      <DialogTitle id="alert-dialog-title">Create a New Customer</DialogTitle>
      <DialogContent>
        <Alert severity="info">{alertMessage}</Alert>

        <TextField
          label="First Name"
          variant="outlined"
          fullWidth
          margin="normal"
          name="firstName"
          value={customer.firstName}
          onChange={onCustomerInputChange}
        />
        <TextField
          label="Last Name"
          variant="outlined"
          fullWidth
          margin="normal"
          name="lastName"
          value={customer.lastName}
          onChange={onCustomerInputChange}
        />
        <TextField
          label="Phone"
          variant="outlined"
          fullWidth
          margin="normal"
          name="phone"
          value={addHyphenToPhoneNumber(customer.phone)}
          onChange={onCustomerInputChange}
        />
        <TextField
          label="Notes"
          variant="outlined"
          fullWidth
          margin="normal"
          name="notes"
          value={customer.notes}
          onChange={onCustomerInputChange}
        />

        <Button
          variant="contained"
          color="secondary"
          onClick={onCreateCallback}
        >
          Create Customer
        </Button>
      </DialogContent>
      <DialogActions>
        <Button
          color="secondary"
          variant="contained"
          onClick={onClose}
        >
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CreateCustomerDialog;