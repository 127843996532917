import Chip from '@mui/material/Chip';

const CheckInStatusChip = ({ checkedIn }) => {
  const text = checkedIn ? "Checked in" : "Pending";
  const color = checkedIn ? "success" : "error"
  return (
    <Chip color={color} label={text} />
  )
};

export default CheckInStatusChip;