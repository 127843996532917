import AppRegistrationIcon from '@mui/icons-material/AppRegistration';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import GroupIcon from '@mui/icons-material/Group';
import RuleIcon from '@mui/icons-material/Rule';
import MessageIcon from '@mui/icons-material/Message';
import AddBusinessIcon from '@mui/icons-material/AddBusiness';
import StoreIcon from '@mui/icons-material/Store';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
export const ADMIN_USER_NAV_ITEMS = [
  {
    name: 'Register Store',
    route: '/register',
    icon: <AddBusinessIcon />,
  },
  {
    name: 'Stores',
    route: '/stores',
    /** TODO: change icon */
    icon: <StoreIcon />,
  },
  {
    name: 'Admins',
    route: '/admins',
    /** TODO: change icon */
    icon: <SupervisorAccountIcon />,
  }

];

export const STORE_FREE_USER_NAV_ITEMS = [
  {
    name: 'Calendar',
    route: '/',
    icon: <CalendarMonthIcon />,
  },
  {
    name: 'Customers',
    route: '/customers',
    icon: <GroupIcon />,
  }
];

export const STORE_PAID_USER_NAV_ITEMS = [
  {
    name: 'Calendar',
    route: '/',
    icon: <CalendarMonthIcon />,
  },
  {
    name: 'Customers',
    route: '/customers',
    icon: <GroupIcon />,
  },
  {
    name: 'Rules',
    route: '/rules',
    icon: <RuleIcon />,
  },
  {
    name: 'Messages',
    route: '/messages',
    icon: <MessageIcon />,
  }
];