import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';

import NavItem from './NavItem';
import { ADMIN_USER_NAV_ITEMS, STORE_FREE_USER_NAV_ITEMS, STORE_PAID_USER_NAV_ITEMS } from './constants';
import { getIsSuperuser, getMembership } from '../../utils/cookies';

const NavDrawer = ({ open, onClose }) => {
  const isSuperUser = getIsSuperuser();
  const NavDrawerItems = isSuperUser
  ? ADMIN_USER_NAV_ITEMS
  : getMembership() === 'Free Tier'
  ? STORE_FREE_USER_NAV_ITEMS
  : STORE_PAID_USER_NAV_ITEMS;

  return (
    <Drawer
      anchor='left'
      open={open}
      onClose={onClose}
    >
      <Box
        sx={{ width: 256 }}
        role='presentation'
      >
        <List>
          {NavDrawerItems.map(item => <NavItem item={item} key={item.name} onClose={onClose} />)}
        </List>
      </Box>
    </Drawer>
  );
};

export default NavDrawer;