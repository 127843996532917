import axiosInstance from '../axios/axiosInstance';

export const createMessageCount = (messageCount) => {
  return axiosInstance.post('/messageCounts/', {
    store: messageCount.store,
    year: messageCount.year,
    month: messageCount.month,
    count: messageCount.count,
    quota: messageCount.quota,
    membership: messageCount.membership,
  });
};

export const getMessageCount = (accountId) => {
  return axiosInstance.get(`/messageCounts/${accountId}/`)
};

export const updateMessageCount = (messageCount) => {
  return axiosInstance.put(`/messageCounts/${messageCount.id}/`, {
    year: parseInt(messageCount.year),
    month: parseInt(messageCount.month),
    count: parseInt(messageCount.count),
    quota: parseInt(messageCount.quota),
    membership: messageCount.membership,
  });
};
