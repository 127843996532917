import axiosInstance from '../axios/axiosInstance';

export const createMessage = (message) => {
  return axiosInstance.post('/smsMessages/', {
    phone: message.phone.replaceAll('-', ''),
    message_body: message.message_body,
    send_at: message.send_at,
  });
};

export const listMessages = (page = 0, pageSize = 20) => {
  return axiosInstance.get(`/smsMessages/?page=${page + 1}&page_size=${pageSize}`);
};

export const getMessage = (messageId) => {
  return axiosInstance.get(`/smsMessages/${messageId}/`);
};

export const updateMessage = (message) => {
  return axiosInstance.put(`/smsMessages/${message.id}/`, {
    first_name: message.first_name,
    last_name: message.last_name,
    phone: message.phone.replaceAll('-', ''),
    message_body: message.message_body,
    send_at: message.send_at,
  });
};


export const deleteMessage = (message) => {
  return axiosInstance.delete(`/smsMessages/${message.id}/`);
};

