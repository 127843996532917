export const convertRawAppointment = (rawAppointment) => {
  return {
    id: rawAppointment.id,
    customer: rawAppointment.customer,
    description: rawAppointment.description,
    start: rawAppointment.start_from,
    end: rawAppointment.end_to,
    title: rawAppointment.title,
    isCheckedIn: rawAppointment.is_checked_in,
  };
};

export const convertRawAppointments = (rawAppointments) => {
  if (!rawAppointments) {
    return [];
  }
  return rawAppointments.map(convertRawAppointment);
};

export const convertRawCustomer = (rawCustomer) => {
  return {
    id: rawCustomer.id,
    createdAt: rawCustomer.created_at,
    store: rawCustomer.store,
    firstName: rawCustomer.first_name,
    lastName: rawCustomer.last_name,
    phone: rawCustomer.phone,
    notes: rawCustomer.notes,
    updatedAt: rawCustomer.updated_at,
    isDeleted: rawCustomer.is_deleted,
  };
};