export const getAppointmentBackgroundColor = (
  isCheckedIn,
  endTime,
) => {
  if (isCheckedIn) {
    return "#94C47E";
  }

  const parsedEndTimestamp = Date.parse(endTime) / 1000;
  const currentTimestamp = Math.floor(Date.now() / 1000);

  if (parsedEndTimestamp < currentTimestamp) {
    return "#e32b3a";
  }
  return "#5c76fa";
};

export const addHyphenToPhoneNumber = (value) => {
  if (typeof value === "undefined" || value === null) {
    return "";
  }

  value.trim();
  value = value.replaceAll("-", "");

  if (value.length > 3 && value.length <= 6)
    value = value.slice(0, 3) + "-" + value.slice(3);
  else if (value.length > 6)
    value = value.slice(0, 3) + "-" + value.slice(3, 6) + "-" + value.slice(6);
  return value;
};

export const removeHyphenFromPhoneNumber = (value) => {
  value = value.replaceAll("-", "");
  return value;
};

export const updateListItemById = (updatedItem, list) => {
  const updatedList = [...list];
    const idx = updatedList.findIndex(rule => rule.id === updatedItem.id);
    if (idx < 0) {
      return updatedList;
    }

    updatedList[idx] = updatedItem;
    return updatedList;
}
