import { useState } from 'react';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';

import { createCustomer, getCustomerByPhone } from '../../apis/customersApis'

import { addHyphenToPhoneNumber } from '../../utils/commonUtil';
import { createAppointment } from '../../apis/appointmentApis';
import { formatDateAddTime } from '../../utils/dateUtil';

const INITIAL_APPOINTMENT = {
  firstName: '',
  lastName: '',
  phone: '',
  durationInMin: 60,
}

const WalkInAppointmentDialog = ({
  open,
  onClose,
  onSuccess,
  onError,
}) => {
  const [walkInAppointment, setWalkInAppointment] = useState({ ...INITIAL_APPOINTMENT });
  const onAppointmentInputChange = (e) => {
    const { name, value } = e.target;
    setWalkInAppointment((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  const onSubmit = async () => {
    let customer;
    try {
      const res = await getCustomerByPhone(walkInAppointment.phone);
      customer = res.data;
    } catch (err) {
      if (err.response?.status === 404) {
        const newCustomer = {
          firstName: walkInAppointment.firstName,
          lastName: walkInAppointment.lastName,
          phone: walkInAppointment.phone,
          notes: '',
        }
        try {
          const res = await createCustomer(newCustomer);
          customer = res.data.customer;
        } catch (err) {
          if (err.response) {
            onError(err.response.data.message);
          }
        }

      }
      // TODO: handle other error 
    }

    if (!customer) {
      return;
    }

    const start = (new Date()).toString();
    const end = formatDateAddTime(start, walkInAppointment.durationInMin)
    const newAppointment = {
      customer: customer.id,
      start,
      end,
      description: '',
      title: `[Walk-in] ${customer.first_name}`,
      isCheckedIn: true,
    };
    try {
      const res = await createAppointment(newAppointment);
      const appointmentCreated = res.data.appointment;
      await onSuccess(appointmentCreated);
      onClose();
      setWalkInAppointment({ ...INITIAL_APPOINTMENT })
    } catch (e) {
      // TODO: handle error
    }

  };
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth={true}
    >
      <DialogTitle id="alert-dialog-title">Walk-in appointment</DialogTitle>
      <DialogContent>
        <TextField
          label="First Name"
          variant="outlined"
          fullWidth
          margin="normal"
          name="firstName"
          value={walkInAppointment.firstName}
          onChange={onAppointmentInputChange}
          required
        />
        <TextField
          label="Last Name"
          variant="outlined"
          fullWidth
          margin="normal"
          name="lastName"
          value={walkInAppointment.lastName}
          onChange={onAppointmentInputChange}
        />
        <TextField
          label="Phone"
          variant="outlined"
          fullWidth
          margin="normal"
          name="phone"
          value={addHyphenToPhoneNumber(walkInAppointment.phone)}
          onChange={onAppointmentInputChange}
        />
      </DialogContent>
      <DialogActions>
        <Button color="primary" variant="contained" onClick={onSubmit}>
          Submit
        </Button>
        <Button
          color="secondary"
          variant="contained"
          onClick={onClose}
        >
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  )
};

export default WalkInAppointmentDialog;