import React, { useState } from 'react';
import {
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper,
  IconButton, TablePagination
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';

const RulesTable = ({ rules, totalCount, rowsPerPage, onRuleEdit, onRuleDelete, onPageChange, onPageSizeChange }) => {
  const [page, setPage] = useState(0);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    onPageChange(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    onPageSizeChange(event.target.value, 10);
  };

  return (
    <TableContainer component={Paper}>
      <Table size='small'>
        <TableHead sx={{ 
            '& .MuiTableRow-root': {  // Targets the TableRow within the TableHead
                backgroundColor: '#354B5E',
            },
            '& .MuiTableCell-head': {  // Optionally, target individual cells if needed
                color: 'white',  // This will make the text color white for better contrast
            }
        }}>
          <TableRow sx={{ '& > *': { padding: '8px 16px' } }}>
            {/* <TableCell>ID</TableCell> */}
            <TableCell>Name</TableCell>
            <TableCell>Description</TableCell>
            <TableCell>Send At (Hours)</TableCell>
            <TableCell>Is Active</TableCell>
            <TableCell>Content</TableCell>
            <TableCell>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody sx={{
          '& .MuiTableRow-root:nth-of-type(odd)': {
              backgroundColor: 'white',
          },
          '& .MuiTableRow-root:nth-of-type(even)': {
              backgroundColor: '#F5F5F5',
          },
        }}>
          {rules.map((row) => (
            <TableRow key={row.id} sx={{ '& > *': { padding: '4px 8px' } }}>
              {/* <TableCell>{row.id}</TableCell> */}
              <TableCell>{row.name}</TableCell>
              <TableCell>{row.description}</TableCell>
              <TableCell>{row.send_at}</TableCell>
              <TableCell>{row.is_active ? <CheckIcon style={{ color: 'green' }} /> : <CloseIcon style={{ color: 'red' }} />}</TableCell>
              <TableCell>{row.content}</TableCell>
              <TableCell>
                <IconButton onClick={() => onRuleEdit(row)}>
                  <EditIcon />
                </IconButton>
                <IconButton onClick={() => onRuleDelete(row)}>
                  <DeleteIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[20, 40, 60]}
        rowsPerPage={rowsPerPage}
        page={page}
        component="div"
        count={totalCount}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </TableContainer>
  );

}

export default RulesTable;
