import React, { useEffect, useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField, Box, Grid, Alert } from '@mui/material';
import { addHyphenToPhoneNumber, updateListItemById } from '../../utils/commonUtil';
import CustomersTable from '../../components/CustomersTable/CustomersTable';

import Spinner from '../../components/Spinner/Spinner';
import { createCustomer, listCustomers, updateCustomer, deleteCustomer, getCustomer } from '../../apis/customersApis'

const CustomersPage = () => {
  const [customers, setCustomers] = useState([]);
  const [spinnerOpen, setSpinnerOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(20);
  const [totalCount, setTotalCount] = useState(0);
  const [searchedFirstName, setSearchedFirstName] = useState('');
  const [searchedLastName, setSearchedLastName] = useState('');
  const [searchedPhone, setSearchedPhone] = useState('');
  const [dialogOpen, setDialogOpen] = useState(false);
  const [editCustomerDialog, setEditCustomerDialog] = useState('');

  const [customer, setCustomer] = useState({
    id: '',
    firstName: '',
    lastName: '',
    phone: '',
    notes: ''
  });

  useEffect(() => {
    fetchPaginatedCustomers(currentPage, pageSize);
  }, [currentPage, pageSize]);

  const fetchPaginatedCustomers = async (page = 0, pageSize) => {
    setSpinnerOpen(true);
    try {
      const res = await listCustomers(page, pageSize);
      setCustomers([...res.data.customers]);
      setTotalCount(res.data.total);
    } catch (e) {

    } finally {
      setSpinnerOpen(false);
    }
  };

  const onPageChangeHandler = (page) => {
    setCurrentPage(page);
  };

  const onPageSizeChangeHandler = (pageSize) => {

    setPageSize(pageSize);
  }

  const onCustomerCreate = () => {
    setEditCustomerDialog('CREATE')
    setDialogOpen(true);
    setCustomer({
      id: '',
      firstName: '',
      lastName: '',
      phone: '',
      notes: ''
    });
  };


  const onCustomerEdit = (customer) => {
    setEditCustomerDialog('EDIT');
    setDialogOpen(true);
    setCustomer({
      id: customer.id,
      firstName: customer.first_name,
      lastName: customer.last_name,
      phone: customer.phone,
      notes: customer.notes,
    });
  };

  const onCustomerDelete = (customer) => {
    setEditCustomerDialog('DELETE');
    setDialogOpen(true);
    setCustomer({
      id: customer.id,
      firstName: customer.first_name,
      lastName: customer.last_name,
      phone: customer.phone,
      notes: customer.notes,
    });
  };

  const handleCreateCustomer = async () => {
    setSpinnerOpen(true);
    try {
      const res = await createCustomer(customer);
      const newCustomer = res.data.customer;
      const updatedCustomers = [...customers, newCustomer];
      setCustomers(updatedCustomers);
    } catch (e) {
      // setIsFlashOpen(true);
      // setFlashMessage(e.response.data.error.message);
    } finally {
      setSpinnerOpen(false);
      setDialogOpen(false);
    }
  };

  const handleCustomerRefreshAfterUpdate = async (customerId) => {
    const { data: updatedCustomer }  = await getCustomer(customerId);

    setCustomers(customers => updateListItemById(updatedCustomer, customers));
  };


  const handleUpdateCustomer = async () => {
    setSpinnerOpen(true);
    try {
      await updateCustomer(customer);
      handleCustomerRefreshAfterUpdate(customer.id);
    } catch (e) {
      // setIsFlashOpen(true);
      // setFlashMessage(e.response.data.error.message);
    } finally {
      setSpinnerOpen(false);
      setDialogOpen(false);
    }
  };

  const handleDeleteCustomer = async () => {
    setSpinnerOpen(true);
    try {
      const res = await deleteCustomer(customer);
      const updatedCustomers = customers.filter(c => c.id != customer.id);
      setCustomers(updatedCustomers);
    } catch (e) {
      // setIsFlashOpen(true);
      // setFlashMessage(e.response.data.error.message);
    } finally {
      setDialogOpen(false);
      setSpinnerOpen(false);
    }
  };


  const handleCustomerChange = (e) => {
    const { name, value } = e.target;
    setCustomer((prev) => ({
      ...prev,
      [name]: value,
    }));
  };


  const renderDialogContentUpdate = (
    <Box component='form' sx={{ m: 2 }}>
      {
        <div>
          <TextField
            label='First Name'
            variant='outlined'
            fullWidth
            margin='normal'
            name='firstName'
            value={customer.firstName}
            onChange={handleCustomerChange}
          />
          <TextField
            label='Last Name'
            variant='outlined'
            fullWidth
            margin='normal'
            name='lastName'
            value={customer.lastName}
            onChange={handleCustomerChange}
          />
          <TextField
            label='Phone'
            variant='outlined'
            fullWidth
            margin='normal'
            name='phone'
            value={addHyphenToPhoneNumber(customer.phone)}
            onChange={handleCustomerChange}
          />
          <TextField
            label='Notes'
            variant='outlined'
            fullWidth
            margin='normal'
            name='notes'
            value={customer.notes}
            onChange={handleCustomerChange}
          />
        </div>
      }
    </Box>
  );

  const renderDialogContentCreate = (
    <Box component='form' sx={{ m: 2 }}>
      {
        <div>
          <TextField
            label='First Name'
            variant='outlined'
            fullWidth
            margin='normal'
            name='firstName'
            value={customer.firstName}
            onChange={handleCustomerChange}
          />
          <TextField
            label='Last Name'
            variant='outlined'
            fullWidth
            margin='normal'
            name='lastName'
            value={customer.lastName}
            onChange={handleCustomerChange}
          />
          <TextField
            label='Phone'
            variant='outlined'
            fullWidth
            margin='normal'
            name='phone'
            value={addHyphenToPhoneNumber(customer.phone)}
            onChange={handleCustomerChange}
          />
          <TextField
            label='Notes'
            variant='outlined'
            fullWidth
            margin='normal'
            name='notes'
            value={customer.notes}
            onChange={handleCustomerChange}
          />
        </div>
      }
    </Box>
  );

  const renderCreateDialogComponent = (
    <Dialog
      open={dialogOpen}
      onClose={() => setDialogOpen(false)}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
      fullWidth={true}
    >
      <DialogTitle id='alert-dialog-title'>Create Customer</DialogTitle>
      <DialogContent>
        {renderDialogContentCreate}
      </DialogContent>
      <DialogActions>
        <Button color='primary' variant='contained' onClick={handleCreateCustomer}> Create</Button>
        <Button color='primary' variant='contained' onClick={() => setDialogOpen(false)}> Cancel</Button>
      </DialogActions>
    </Dialog>
  );


  const renderUpdateDialogComponent = (
    <Dialog
      open={dialogOpen}
      onClose={() => setDialogOpen(false)}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
      fullWidth={true}
    >
      <DialogTitle id='alert-dialog-title'>Update Customer</DialogTitle>
      <DialogContent>
        {renderDialogContentUpdate}
      </DialogContent>
      <DialogActions>
        <Button color='primary' variant='contained' onClick={handleUpdateCustomer}> Save</Button>
        <Button color='primary' variant='contained' onClick={() => setDialogOpen(false)}> Cancel</Button>
      </DialogActions>
    </Dialog>
  );

  const renderDeleteDialogComponent = (
    <Dialog
      open={dialogOpen}
      onClose={() => setDialogOpen(false)}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
      fullWidth={true}
    >
      <DialogTitle id='alert-dialog-title'>Are you sure you want to delete customer</DialogTitle>

      {/* <DialogContent>
          Are you sure you want to delete customer
      </DialogContent> */}
      <DialogActions>
        <Button color='primary' variant='contained' onClick={handleDeleteCustomer}> Delete</Button>
        <Button color='primary' variant='contained' onClick={() => setDialogOpen(false)}> Cancel</Button>
      </DialogActions>
    </Dialog>
  );


  return (
    <Box p={2}>
      <Spinner open={spinnerOpen} />

      <Grid item xs={true} sm={9}>
        <TextField
          label='First Name'
          variant='outlined'
          size='small'
          margin='normal'
          value={searchedFirstName}
          onChange={e => setSearchedFirstName(e.target.value)}
        />
        <TextField
          label='Last Name'
          variant='outlined'
          size='small'
          margin='normal'
          value={searchedLastName}
          onChange={e => setSearchedLastName(e.target.value)}
        />
        <TextField
          label='Phone'
          variant='outlined'
          size='small'
          margin='normal'
          value={addHyphenToPhoneNumber(searchedPhone)}
          onChange={e => setSearchedPhone(e.target.value.replaceAll('-', ''))}
        />
        <Button style={{ float: 'right' }} color='primary' variant='contained' onClick={onCustomerCreate}> Create Customer</Button>

      </Grid>


      <CustomersTable
        rowsPerPage={pageSize}
        totalCount={totalCount}
        customers={customers.filter((customer) => 
          (!searchedFirstName || (customer.first_name && customer.first_name.toLowerCase().includes(searchedFirstName.toLowerCase()))) &&
          (!searchedLastName || (customer.last_name && customer.last_name.toLowerCase().includes(searchedLastName.toLowerCase()))) &&
          (!searchedPhone || (customer.phone && customer.phone.includes(searchedPhone)))
      )}
        onPageChange={onPageChangeHandler}
        onPageSizeChange={onPageSizeChangeHandler}
        onCustomerEdit={onCustomerEdit}
        onCustomerDelete={onCustomerDelete}
      />
      {
        editCustomerDialog === 'CREATE' ? renderCreateDialogComponent
          : editCustomerDialog === 'EDIT' ? renderUpdateDialogComponent
            : renderDeleteDialogComponent
      }

    </Box>
  );
};

export default CustomersPage;
