import React, { useState } from 'react';
import {
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper,
  IconButton, TablePagination
} from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';

import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { converToLocal } from "../../utils/dateUtil";
import { addHyphenToPhoneNumber } from '../../utils/commonUtil';

const MessagesTable = ({ messages, totalCount, rowsPerPage, onMessageEdit, onMessageDelete, onPageChange, onPageSizeChange }) => {
  const [page, setPage] = useState(0);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    onPageChange(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    onPageSizeChange(event.target.value, 10);
  };

  return (
    <TableContainer component={Paper}>
      <Table size='small'>
        <TableHead sx={{ 
            '& .MuiTableRow-root': {  // Targets the TableRow within the TableHead
                backgroundColor: '#354B5E',
            },
            '& .MuiTableCell-head': {  // Optionally, target individual cells if needed
                color: 'white',  // This will make the text color white for better contrast
            }
        }}>
          <TableRow sx={{ '& > *': { padding: '8px 16px' } }}>
            {/* <TableCell>ID</TableCell> */}
            <TableCell>First Name</TableCell>
            <TableCell>Last Name</TableCell>
            <TableCell>Phone</TableCell>
            <TableCell>Message Body</TableCell>
            <TableCell>Send At</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody sx={{
          '& .MuiTableRow-root:nth-of-type(odd)': {
              backgroundColor: 'white',
          },
          '& .MuiTableRow-root:nth-of-type(even)': {
              backgroundColor: '#F5F5F5',
          },
        }}>
          {messages.map((row) => (
            <TableRow key={row.id} sx={{ '& > *': { padding: '4px 8px' } }}>
              {/* <TableCell>{row.id}</TableCell> */}
              <TableCell>{row.first_name}</TableCell>
              <TableCell>{row.last_name}</TableCell>
              <TableCell>{addHyphenToPhoneNumber(row.phone)}</TableCell>
              <TableCell>{row.message_body}</TableCell>
              <TableCell>{converToLocal(row.send_at)}</TableCell>
              <TableCell>{row.status}</TableCell>
              <TableCell>
                <IconButton onClick={() => onMessageEdit(row)}>
                  <EditIcon />
                </IconButton>
                <IconButton onClick={() => onMessageDelete(row)}>
                  <DeleteIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[20, 40, 60]}
        rowsPerPage={rowsPerPage}
        page={page}
        component="div"
        count={totalCount}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </TableContainer>
  );

}

export default MessagesTable;
