import axiosInstance from "../axios/axiosInstance";
import { converToUTC } from "../utils/dateUtil";
import { convertRawAppointment, convertRawAppointments } from "./utils";

// TODO: use these interfaces once migrate to typescript
// export interface RawAppointment {
//   id: number;
//   customer: number;
//   description: string;
//   start_from: string;
//   end_to: string;
//   title: string;
//   is_checked_in: boolean;
// }

// export interface Appointment {
//   id: number;
//   customer: number;
//   description: string;
//   start: string;
//   end: string;
//   title: string;
//   isCheckedIn: boolean;
// }

export const getAppointment = async (apptId) => {
  const response = await axiosInstance.get(`/appointments/${apptId}`);
  return convertRawAppointment(response.data);
};

export const listAppointments = async () => {
  const response = await axiosInstance.get("/appointments/");
  return convertRawAppointments(response.data.appointments);
};

export const createAppointment = (appointment) => {
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  return axiosInstance.post("/appointments/", {
    customer: appointment.customer,
    start_from: converToUTC(appointment.start),
    end_to: converToUTC(appointment.end),
    description: appointment.description,
    title: appointment.title,
    is_checked_in: appointment.isCheckedIn,
    time_zone: timeZone,
  });
};

export const updateAppointment = (appointment) => {
  return axiosInstance.put(`/appointments/${appointment.id}/`, {
    customer: appointment.customer,
    start_from: converToUTC(appointment.start),
    end_to: converToUTC(appointment.end),
    description: appointment.description,
    title: appointment.title,
    is_checked_in: appointment.isCheckedIn,
    is_deleted: appointment.isDeleted,
  });
};

export const bulkUpdateAppointments = (appointmentIds, updateToStatus) => {
  return axiosInstance.put(`/appointments/bulk-check-in/`, {
    ids: appointmentIds,
    updateToStatus: updateToStatus,
  });
};

export const deleteAppointment = (appointmentId) => {
  return axiosInstance.delete(`/appointments/${appointmentId}`);
}
