import { useState, useEffect } from "react";
import { getAppointment, listAppointments } from "../apis/appointmentApis";

export const useAppointments = () => {
  const [appointments, setAppointments] = useState([]);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const fetchAppointments = async (showLoadingStatus = true) => {

    try {
      showLoadingStatus && setLoading(true);
      const appointments = await listAppointments();
      setAppointments(appointments);
    } catch (error) {
      console.log(error)
      setErrorMessage(error);
    } finally {
      showLoadingStatus && setLoading(false);
    }
  };

  const refreshSingleAppointment = async (apptId) => {
    try {
      const updatedAppt = await getAppointment(apptId);
      const updatedAppts = [...appointments];
      const updateIdx = updatedAppts.findIndex(appt => appt.id === apptId);
      if (updateIdx < 0) {
        updatedAppts.push(updatedAppt);
      } else {
        updatedAppts[updateIdx] = { ...updatedAppt };
      }
      setAppointments(updatedAppts);
    } catch (error) {
      if (error?.resposne?.status === 404) {
        // TODO: update to log api call
        console.log(`Appointment ${apptId} not found`)
        return;
      }
      throw error;
    }

  };

  const refetchWithoutLoadingStatus = () => fetchAppointments(false);

  useEffect(() => {
    fetchAppointments();
  }, []);

  return {
    appointments,
    loading,
    errorMessage,
    fetchAppointments,
    refetchWithoutLoadingStatus,
    refreshSingleAppointment,
  };
};
