import { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import SearchIcon from "@mui/icons-material/Search";

import { useCustomer } from '../../hooks/customer';
import { addHyphenToPhoneNumber, removeHyphenFromPhoneNumber } from '../../utils/commonUtil';

const CustomerSearchByPhoneDialog = ({ open, onClose, onFound, onNotFound }) => {
  const [phoneNumber, setPhoneNumber] = useState('');
  const [customerLookup, setCustomerLookup] = useState({});
  const {
    customer,
    loading,
    clearCustomer,
  } = useCustomer(customerLookup);
  useEffect(() => {
    if (loading) {
      return;
    }
    if (customer === undefined) {
      return;
    }
    if (customer === null) {
      onNotFound(phoneNumber);
    } else {
      onFound(customer.id);
    }

    setPhoneNumber('');
    setCustomerLookup({});
    clearCustomer();


  }, [customer, loading, clearCustomer, onFound, onNotFound, phoneNumber]);
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth={true}
    >
      <DialogTitle id="alert-dialog-title">Search for Customer by Phone</DialogTitle>
      <DialogContent>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={true} sm={9}>
            <TextField
              label="Phone"
              variant="outlined"
              fullWidth
              margin="normal"
              value={addHyphenToPhoneNumber(phoneNumber)}
              onChange={(e) => setPhoneNumber(e.target.value)}
            />
          </Grid>

          <Grid item xs={true} sm={3}>
            <Button
              color="primary"
              variant="contained"
              size="large"
              disabled={!phoneNumber}
              onClick={() => setCustomerLookup({ phone: removeHyphenFromPhoneNumber(phoneNumber) })}
              startIcon={<SearchIcon />}
            >
              Search
            </Button>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          color="secondary"
          variant="contained"
          onClick={onClose}
        >
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CustomerSearchByPhoneDialog;