import { sliceEvents, createPlugin } from '@fullcalendar/core';
import CustomList from './CustomListView/CustomList';

const CustomListView = (props) => {
  const events = sliceEvents(props, true);

  return (
    <CustomList dateProfile={props.dateProfile} events={events} selectable />
  );
};

export default createPlugin({
  views: {
    custom: CustomListView,
  }
});