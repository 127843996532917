import React, { useEffect, useState } from 'react';
import {Select, MenuItem, Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField, Box, Grid, Alert } from '@mui/material';

import StoresTable from '../../components/StoresTable/StoresTable';
import Spinner from '../../components/Spinner/Spinner';

import { listStores, updateStore, deleteStore, getStore} from '../../apis/storesApis'
import { getConfig} from '../../apis/accountApis'
import { addHyphenToPhoneNumber, updateListItemById } from '../../utils/commonUtil';

const StoresPage = () => {
  const [stores, setStores] = useState([]);
  const [spinnerOpen, setSpinnerOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(20);
  const [totalCount, setTotalCount] = useState(0);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [editStoreDialog, setEditStoreDialog] = useState('');
  const [config, setConfig] = useState([]);

  const [store, setStore] = useState({
    id: '',
    username: '',
    first_name: '',
    last_name: '',
    email: '',
    phone: '',
    default_appointment_duration: '',
  });


  useEffect(() => {
    fetchPaginatedStores(currentPage, pageSize);
  }, [currentPage, pageSize]);

  const fetchPaginatedStores =  async (page = 0, pageSize) => {
    setSpinnerOpen(true);
    try {
      const res = await listStores(page, pageSize);
      setStores([...res.data.stores]);
      setTotalCount(res.data.total);
    } catch(e) {

    } finally {
      setSpinnerOpen(false);
    }
  };

  useEffect(() => {
    fetchConfig();
  }, []);

  const fetchConfig =  async () => {
    setSpinnerOpen(false);
    try {
      const res = await getConfig();      
      setConfig({
        config: res.data['config'],
      });
    } catch(e) {

    } finally {
      setSpinnerOpen(false);
    }
  };

  const onPageChangeHandler = (page) => {
    setCurrentPage(page);
  };

  const onPageSizeChangeHandler = (pageSize) => {
    setPageSize(pageSize);
  }
  const onStoreEdit = (store) => {
    setEditStoreDialog('EDIT');
    setDialogOpen(true);
    setStore({
      id: store.user['id'],
      username: store.user['username'],
      email: store.user['email'],
      first_name: store.user['first_name'],
      last_name: store.user['last_name'],
      phone: store.phone,
      default_appointment_duration: store.default_appointment_duration,
    });
  };

  const onStoreDelete = (storeId) => {
    setEditStoreDialog('DELETE');
    setDialogOpen(true);
    setStore({
      id: storeId,
    });
  };

  const handleStoreRefreshAfterUpdate = async (storeId) => {
    const { data: updatedStore }  = await getStore(storeId);

    setStores(stores => updateListItemById(updatedStore, stores));
  };


  const handleUpdateStore = async () => {
    setSpinnerOpen(true);
    try {
      await updateStore(store);
      handleStoreRefreshAfterUpdate(store.id);
    } catch (e) {
      // setIsFlashOpen(true);
      // setFlashMessage(e.response.data.error.message);
    } finally {
      setSpinnerOpen(false);
      setDialogOpen(false);
    }
  };

  const handleDeleteStore = async () => {
    setSpinnerOpen(true);
    try {
      const res = await deleteStore(store.id);
      const updatedStores = stores.filter(c => c.id != store.id);
      setStores(updatedStores);
    } catch (e) {
      // setIsFlashOpen(true);
      // setFlashMessage(e.response.data.error.message);
    } finally {
      setDialogOpen(false);
      setSpinnerOpen(false);
    }
  };

  const handleStoreChange = (e) => {
    const { name, value } = e.target;
    setStore((prev) => ({
      ...prev,
      [name]: value,
    }));
  };


  const renderDialogContentUpdate = (
    <Box component='form' sx={{ m: 2 }}>
      {
        <div>
          <TextField
            label='Store Name'
            variant='outlined'
            fullWidth
            margin='normal'
            name='store_name'
            value={store.username}
            onChange={handleStoreChange}
            disabled
          />
          <TextField
            label='Email'
            variant='outlined'
            fullWidth
            margin='normal'
            name='email'
            value={store.email}
            onChange={handleStoreChange}
          />
          <TextField
            label='Phone'
            variant='outlined'
            fullWidth
            margin='normal'
            name='phone'
            value={addHyphenToPhoneNumber(store.phone)}
            onChange={handleStoreChange}
          />
          <TextField
            label='Default Appointment Duration(mins)'
            variant='outlined'
            fullWidth
            margin='normal'
            name='default_appointment_duration'
            value={store.default_appointment_duration}
            onChange={handleStoreChange}
          />
          <TextField
            label='First name'
            variant='outlined'
            fullWidth
            margin='normal'
            name='first_name'
            value={store.first_name}
            onChange={handleStoreChange}
          />
          <TextField
            label='Last Name'
            variant='outlined'
            fullWidth
            margin='normal'
            name='last_name'
            value={store.last_name}
            onChange={handleStoreChange}
          />

        </div>
      }
    </Box>
  );



  const renderUpdateDialogComponent = (
    <Dialog
      open={dialogOpen}
      onClose={() => setDialogOpen(false)}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
      fullWidth={true}
    >
      <DialogTitle id='alert-dialog-title'>Update Store</DialogTitle>
      <DialogContent>
        {renderDialogContentUpdate}
      </DialogContent>
      <DialogActions>
        <Button color='primary' variant='contained' onClick={handleUpdateStore}> Save</Button>
        <Button color='primary' variant='contained' onClick={() => setDialogOpen(false)}> Cancel</Button>
      </DialogActions>
    </Dialog>
  );

  const renderDeleteDialogComponent = (
    <Dialog
      open={dialogOpen}
      onClose={() => setDialogOpen(false)}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
      fullWidth={true}
    >
      <DialogTitle id='alert-dialog-title'>Are you sure you want to delete store</DialogTitle>
      <DialogActions>
        <Button color='primary' variant='contained' onClick={handleDeleteStore}> Delete</Button>
        <Button color='primary' variant='contained' onClick={() => setDialogOpen(false)}> Cancel</Button>
      </DialogActions>
    </Dialog>
  );

  return (
    <Box p={2}>
      <Spinner open={spinnerOpen} />
      <StoresTable
        rowsPerPage={pageSize}
        totalCount={totalCount}
        stores={stores}
        onPageChange={onPageChangeHandler}
        onPageSizeChange={onPageSizeChangeHandler}
        onStoreEdit={onStoreEdit}
        onStoreDelete={onStoreDelete}
      />
      {
          editStoreDialog === 'EDIT' ? renderUpdateDialogComponent
          : renderDeleteDialogComponent
      }
    </Box>
  );
};

export default StoresPage;
