import axiosInstance from '../axios/axiosInstance';


export const createAdmin = (admin) => {
    return axiosInstance.post('/admins/', {
        username: admin.username,
        password: admin.password,
        first_name: admin.firstName,
        last_name: admin.lastName,
        email: admin.email,
        phone: admin.phone.replaceAll('-', ''),
    });
  };
  
export const listAdmins = (page = 0, pageSize = 20) => {
  return axiosInstance.get(`/admins/?page=${page + 1}&page_size=${pageSize}`);
};

export const getAdmin = (adminId) => {
  return axiosInstance.get(`/admins/${adminId}/`)
};

export const updateAdmin = (admin) => {
  return axiosInstance.put(`/admins/${admin.id}/`, {
    user: {
      id: admin.id,
      username: admin.username,
      email: admin.email,
      first_name: admin.first_name,
      last_name: admin.last_name,
    },
    phone: admin.phone.replaceAll('-', ''),
  });
};

export const deleteAdmin = (admin) => {
return axiosInstance.delete(`/admins/${admin.id}/`);
};