import axiosInstance from '../axios/axiosInstance';

export const createCustomer = (customer) => {
  return axiosInstance.post('/customers/', {
    first_name: customer.firstName,
    last_name: customer.lastName,
    phone: customer.phone.replaceAll('-', ''),
    notes: customer.notes,
  });
};

export const listCustomers = (page = 0, pageSize = 20) => {
  return axiosInstance.get(`/customers/?page=${page + 1}&page_size=${pageSize}`);
};

export const getCustomerByPhone = (phone) => {
  return axiosInstance.get(`/customers/by-phone/?phone=${phone.replaceAll('-', '')}`);
};

export const getCustomer = (customerId) => {
  return axiosInstance.get(`/customers/${customerId}/`)
};

export const updateCustomer = (customer) => {
  return axiosInstance.put(`/customers/${customer.id}/`, {
    first_name: customer.firstName,
    last_name: customer.lastName,
    phone: customer.phone.replaceAll('-', ''),
    notes: customer.notes,
  });
};

export const deleteCustomer = (customer) => {
  return axiosInstance.delete(`/customers/${customer.id}/`);
};