import React, {useEffect, useState } from 'react';
import {MenuItem, Select, Button, TextField, Paper, Typography, Container } from '@mui/material';

import Spinner from '../Spinner/Spinner';

import { signup } from '../../apis/accountApis'
import { getConfig} from '../../apis/accountApis'

import { errorMessage } from '../../messages/messages';
import {addHyphenToPhoneNumber} from '../../utils/commonUtil'

const SignUpForm = ({ handleRegister, handleRegisterErrorMsg }) => {
  const [credentials, setCredentials] = useState({
    email: '',
    username: '',
    password: '',
    reEnterPassword: '',
    phone: '',
    first_name: '',
    last_name: ''
  });
  const [error, setError] = useState('');
  const [spinnerOpen, setSpinnerOpen] = useState(false);
  const [config, setConfig] = useState([]);

  useEffect(() => {
    fetchConfig();
  }, []);

  const fetchConfig =  async () => {
    setSpinnerOpen(false);
    try {
      const res = await getConfig();      
      setConfig({
        config: res.data['config'],
      });
    } catch(e) {

    } finally {
      setSpinnerOpen(false);
    }
  };


  const handleChange = (e) => {
    const { name, value } = e.target;
    setCredentials((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (credentials.password !== credentials.reEnterPassword) {
      setError(errorMessage.PasswordNotMatch);
      return;
    }
    // Handle sign-up logic here
    setSpinnerOpen(true);
    try{
      const res = await signup(credentials.username, credentials.password, credentials.email, credentials.phone.replaceAll('-', ''), credentials.first_name, credentials.last_name);
      setCredentials({ username: '', password: '', email: '', reEnterPassword: '' , phone: '', first_name: '', last_name: ''});
      handleRegister(res.data.user.username);
    } catch(e) {
      handleRegisterErrorMsg(e.response.data['errors']['message']);
    } finally {
      setSpinnerOpen(false);
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <Spinner open={spinnerOpen} />
      <Paper
        elevation={3}
        style={{ padding: 16, display: 'flex', flexDirection: 'column', alignItems: 'center' }}
      >
        <Typography component="h1" variant="h5">
          Sign Up
        </Typography>
        <form onSubmit={handleSubmit} style={{ width: '100%', marginTop: 8 }}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
            value={credentials.email}
            onChange={handleChange}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="username"
            label="Username"
            name="username"
            autoComplete="username"
            value={credentials.username}
            onChange={handleChange}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="new-password"
            value={credentials.password}
            onChange={handleChange}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="reEnterPassword"
            label="Re-enter Password"
            type="password"
            id="reEnterPassword"
            value={credentials.reEnterPassword}
            onChange={handleChange}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="phone"
            label="Phone"
            name="phone"
            value={addHyphenToPhoneNumber(credentials.phone)}
            onChange={handleChange}
          />
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            id="first_name"
            label="First Name"
            name="first_name"
            value={credentials.first_name}
            onChange={handleChange}
            InputProps={{ endAdornment: <Typography variant="caption">(Optional)</Typography> }}

          />
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            id="last_name"
            label="Last Name"
            name="last_name"
            value={credentials.last_name}
            onChange={handleChange}
            InputProps={{ endAdornment: <Typography variant="caption">(Optional)</Typography> }}
          />
          {error && (
            <Typography color="error" style={{ marginTop: 8 }}>
              {error}
            </Typography>
          )}
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            style={{ marginTop: 16 }}
          >
            Sign Up
          </Button>
        </form>
      </Paper>
    </Container>
  );
}

export default SignUpForm;
