
import { useState, useEffect } from 'react';
import { getCustomer, getCustomerByPhone } from '../apis/customersApis';
import { convertRawCustomer } from '../apis/utils';

/**
 * 
 * @param {
 *   id?: number,
 *   phone?: string,
 * } customerIdAndPhone: will use id for lookup if provided.
 */
export const useCustomer = (customerLookup, onFound, onNotFound) => {
  const [customer, setCustomer] = useState();
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    const fetchCustomer = async () => {
      const { id, phone } = customerLookup;
      if (id === undefined && phone === undefined) {
        return;
      }
      setLoading(true);

      const getCustomerApiCall = id !== undefined ? () => getCustomer(id) : () => getCustomerByPhone(phone)
      try {
        const res = await getCustomerApiCall();
        setCustomer(convertRawCustomer(res.data));
      } catch (error) {
        // TODO: update to log api call

        if (error?.response?.status === 404) {
          setCustomer(null);
          return;
        }
        console.log(error);
        if (error?.response) {
          setErrorMessage(JSON.stringify(error.response.data))
        }
      } finally {
        setLoading(false);
      }
    };
    fetchCustomer();
  }, [customerLookup]);

  const clearCustomer = () => {
    setCustomer(undefined);
    setLoading(false);
    setErrorMessage('');
  };

  return {
    customer,
    loading,
    errorMessage,
    clearCustomer,
  }
};