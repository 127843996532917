import axiosInstance from '../axios/axiosInstance';

export const listStores = (page = 0, pageSize = 20) => {
  return axiosInstance.get(`/stores/?page=${page + 1}&page_size=${pageSize}`);
};

export const getStore = (storeId) => {
  return axiosInstance.get(`/stores/${storeId}/`)
};

export const getCurrentStore = () => {
  return axiosInstance.get(`/stores/current/`)
};

export const updateStore = (store) => {
  return axiosInstance.put(`/stores/${store.id}/`, {
    user: {
      id: store.id,
      username: store.username,
      email: store.email,
      first_name: store.first_name,
      last_name: store.last_name,
    },
    phone: store.phone.replaceAll('-', ''),
    membership: store.membership,
    default_appointment_duration: store.default_appointment_duration,
  });
};

export const deleteStore = (storeId) => {
return axiosInstance.delete(`/stores/${storeId}/`);
};