export const infoMessages = {
  LoginRequiredMsg: 'Please initiate the login process',
};

export const successMessages = {
  SignOffSuccessMsg: 'Signed off successfully',
  SignUpSuccessMsg: 'Registered new account successfully',
};

export const errorMessage = {
  PasswordNotMatch: 'Passwords do not match!',
};
