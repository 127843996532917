import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Checkbox from '@mui/material/Checkbox';
import Typography from '@mui/material/Typography';

const CustomListRow = ({ event, columns, selectable, selectedEventIds, setSelectedEventIds }) => {
  const eventId = event.def.extendedProps.appointment.id;
  const checked = selectedEventIds.includes(eventId);
  const onCheckboxChange = (event) => {
    const checked = event.target.checked;

    if (checked) {
      setSelectedEventIds(eventIds => [...eventIds, eventId]);
    } else {
      setSelectedEventIds(eventIds => eventIds.filter(eId => eId !== eventId))
    }
  };
  return (
    <TableRow >
      {selectable && <TableCell padding="checkbox"><Checkbox checked={checked} onChange={onCheckboxChange} /></TableCell>}
      {columns.map(column => ((
        <TableCell key={column.name} align="center" >
          {column.getValue(event)}
        </TableCell>
      )))
      }
    </TableRow >
  );
};

export default CustomListRow;