import React, { useEffect, useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField, Tab, Tabs, Box, Grid, Alert, Select, MenuItem } from '@mui/material';

import MessageCountsTable from '../../components/MessageCountsTable/MessageCountsTable';
import Spinner from '../../components/Spinner/Spinner';

import { getMessageCount, updateMessageCount, createMessageCount} from '../../apis/messageCountsApis'
import { getConfig} from '../../apis/accountApis'
import { getStore} from '../../apis/storesApis'
import { useParams } from 'react-router';

const MessageCountsPage = () => {
  const [messageCounts, setMessageCounts] = useState([]);
  const [spinnerOpen, setSpinnerOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(20);
  const [totalCount, setTotalCount] = useState(0);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [editMessageCountDialog, setEditMessageCountDialog] = useState('');

  const [store, setStore] = useState([]);
  const [config, setConfig] = useState([]);

  const params= useParams();

  const [messageCount, setMessageCount] = useState({
    id: '',
    store: params.accountId,
    year: '',
    month: '',
    count: '',
    quota: '',
    membership: '',
  });

  useEffect(() => {
    fetchPaginatedMessageCounts(currentPage, pageSize);
  }, [currentPage, pageSize]);

  const fetchPaginatedMessageCounts =  async (page = 0, pageSize) => {
    setSpinnerOpen(true);
    try {
      const res = await getMessageCount(params.accountId);

      setMessageCounts([...res.data.messageCounts]);
      setTotalCount(res.data.total);
    } catch(e) {

    } finally {
      setSpinnerOpen(false);
    }
  };


  useEffect(() => {
    fetchConfig();
  }, []);

  const fetchConfig =  async () => {
    setSpinnerOpen(false);
    try {
      const res = await getConfig();      
      setConfig({
        config: res.data['config'],
      });
    } catch(e) {

    } finally {
      setSpinnerOpen(false);
    }
  };


  useEffect(() => {
    fetchStore();
  }, []);

  const fetchStore =  async () => {
    setSpinnerOpen(false);
    try {
      const res = await getStore(params.accountId);
      const responseStore = res.data;
      setStore({
        id: responseStore.user['id'],
        username: responseStore.user['username'],
        email: responseStore.user['email'],
        first_name: responseStore.user['first_name'],
        last_name: responseStore.user['last_name'],
        phone: responseStore.phone,
        membership: responseStore.membership,
        default_appointment_duration: responseStore.default_appointment_duration,
      });
    } catch(e) {

    } finally {
      setSpinnerOpen(false);
    }
  };

  const handleMessageCountChange = (e) => {
    const { name, value } = e.target;
    setMessageCount((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleCreateMessageCount = async () => {
    setSpinnerOpen(true);
    try {
      const res = await createMessageCount(messageCount);
      const newMessageCount = res.data.messageCount;
      const updatedMessageCounts = [...messageCounts, newMessageCount];
      setMessageCounts(updatedMessageCounts);
    } catch (e) {
      // setIsFlashOpen(true);
      // setFlashMessage(e.response.data.error.message);
    } finally {
      setSpinnerOpen(false);
      setDialogOpen(false);
    }
  };

  const handleUpdateMessageCount = async () => {
    setSpinnerOpen(true);
    try {
      const res = await updateMessageCount(messageCount);
      const updatedMessageCount = res.data['messageCounts'];
      setMessageCounts(prevMessageCounts => {
        // Create a new array based on the previous messageCounts
        const updatedMessageCounts = [...prevMessageCounts];
        const index = updatedMessageCounts.findIndex(e => e.id === updatedMessageCount.id);
        if (index !== -1) {
          updatedMessageCounts[index] = updatedMessageCount;
        }
        return updatedMessageCounts;
      });
    } catch (e) {
      // setIsFlashOpen(true);
      // setFlashMessage(e.response.data.error.message);
    } finally {
      setSpinnerOpen(false);
      setDialogOpen(false);
    }
  };


  const onPageChangeHandler = (page) => {
    setCurrentPage(page);
  };

  const onPageSizeChangeHandler = (pageSize) => {
    setPageSize(pageSize);
  }

  const onMessageCountCreate = () => {
    setEditMessageCountDialog('CREATE')
    setDialogOpen(true);
    const keys = Object.keys(config.config)

    setMessageCount({
      store: params.accountId,
      id: '',
      year: '',
      month: '',
      count: '',
      quota: config.config[store.membership],
      membership: store.membership,
    });
  };

  const onMessageCountEdit = (messageCount) => {
    setEditMessageCountDialog('EDIT');
    setDialogOpen(true);
    setMessageCount({
      id: messageCount.id,
      store: messageCount.store,
      year: messageCount.year,
      month: messageCount.month,
      count: messageCount.count,
      quota: messageCount.quota,
      membership: messageCount.membership
    });
  };


  const renderDialogContentCreate = (
    <Box component='form' sx={{ m: 2 }}>
      {
        <div>
          <TextField
            label='Year'
            variant='outlined'
            fullWidth
            margin='normal'
            name='year'
            value={messageCount.year}
            onChange={handleMessageCountChange}
          />
          <TextField
            label='Month'
            variant='outlined'
            fullWidth
            margin='normal'
            name='month'
            value={messageCount.month}
            onChange={handleMessageCountChange}
          />
          <TextField
            label='Count'
            variant='outlined'
            fullWidth
            margin='normal'
            name='count'
            value={messageCount.count}
            onChange={handleMessageCountChange}
          />
          <TextField
            label='Quota'
            variant='outlined'
            fullWidth
            margin='normal'
            name='quota'
            value={messageCount.quota}
            onChange={handleMessageCountChange}
          />
          <TextField
            label='Membership'
            variant='outlined'
            fullWidth
            margin='normal'
            name='membership'
            value={messageCount.membership}
            onChange={handleMessageCountChange}
          />

        </div>
      }
    </Box>
  );

  const renderDialogContentUpdate = (
    <Box component='form' sx={{ m: 2 }}>
      {
        <div>
          <TextField
            disabled
            label='Year'
            variant='outlined'
            fullWidth
            margin='normal'
            name='year'
            value={messageCount.year}
            onChange={handleMessageCountChange}
          />
          <TextField
            disabled
            label='Month'
            variant='outlined'
            fullWidth
            margin='normal'
            name='month'
            value={messageCount.month}
            onChange={handleMessageCountChange}
          />
          <TextField
            disabled
            label='Count'
            variant='outlined'
            fullWidth
            margin='normal'
            name='count'
            value={messageCount.count}
            onChange={handleMessageCountChange}
          />
          <TextField
            label='Quota'
            variant='outlined'
            fullWidth
            margin='normal'
            name='quota'
            value={messageCount.quota}
            onChange={handleMessageCountChange}
          />
          <Select
            label='Membership'
            variant='outlined'
            fullWidth
            margin='normal'
            name='membership'
            value={messageCount.membership}
            onChange={handleMessageCountChange}
          >
            {config.config && Object.keys(config.config).map((membershipOption) => (
              <MenuItem key={membershipOption} value={membershipOption} selected={messageCount.membership === membershipOption}>
                {membershipOption}
              </MenuItem>
            ))}
          </Select>
        </div>
      }
    </Box>
  );

  const renderCreateDialogComponent = (
    <Dialog
      open={dialogOpen}
      onClose={() => setDialogOpen(false)}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
      fullWidth={true}
    >
      <DialogTitle id='alert-dialog-title'>Create Rule</DialogTitle>
      <DialogContent>
        {renderDialogContentCreate}
      </DialogContent>
      <DialogActions>
        <Button color='primary' variant='contained' onClick={handleCreateMessageCount}> Create</Button>
        <Button color='primary' variant='contained' onClick={() => setDialogOpen(false)}> Cancel</Button>
      </DialogActions>
    </Dialog>
  );



  const renderUpdateDialogComponent = (
    <Dialog
      open={dialogOpen}
      onClose={() => setDialogOpen(false)}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
      fullWidth={true}
    >
      <DialogTitle id='alert-dialog-title'>Update messageCount</DialogTitle>
      <DialogContent>
        {renderDialogContentUpdate}
      </DialogContent>
      <DialogActions>
        <Button color='primary' variant='contained' onClick={handleUpdateMessageCount}> Save</Button>
        <Button color='primary' variant='contained' onClick={() => setDialogOpen(false)}> Cancel</Button>
      </DialogActions>
    </Dialog>
  );

  return (
    <Box p={2}>
      <Spinner open={spinnerOpen} />

      {/* <Grid item xs={true} sm={9}> */}
        <Button style={{ float: 'right' }} color='primary' variant='contained' onClick={onMessageCountCreate}> Create Message Count</Button>
      {/* </Grid> */}

      <MessageCountsTable
        rowsPerPage={pageSize}
        totalCount={totalCount}
        messageCounts={messageCounts}
        onPageChange={onPageChangeHandler}
        onPageSizeChange={onPageSizeChangeHandler}
        onMessageCountEdit={onMessageCountEdit}
      />

      {
        editMessageCountDialog === 'CREATE' ? renderCreateDialogComponent
        : renderUpdateDialogComponent
      }

    </Box>
  );
};

export default MessageCountsPage;