import "./CalendarScheduler.css";
import React, { useEffect, useMemo, useState, useCallback } from "react";
import FullCalendar from "@fullcalendar/react";
import interactionPlugin from "@fullcalendar/interaction"; // for selectable
import dayGridPlugin from "@fullcalendar/daygrid"; // a plugin!
import listPlugin from "@fullcalendar/list";
import timeGridPlugin from "@fullcalendar/timegrid";
import multiMonthPlugin from "@fullcalendar/multimonth";
import customListViewPlugin from "./CustomListViewPlugIn";

import Spinner from "../Spinner/Spinner";
import FlashMessage from "../FlashMessage/FlashMessage";

import { createCustomer } from "../../apis/customersApis";
import {
  createAppointment,
  updateAppointment,
  deleteAppointment,
  bulkUpdateAppointments
} from "../../apis/appointmentApis";

import { formatDate, formatDateAddTime } from "../../utils/dateUtil";
import { getAppointmentBackgroundColor } from "../../utils/commonUtil";
import { useAppointments } from "../../hooks/appointments";
import { useCustomer } from '../../hooks/customer';
import CustomerSearchByPhoneDialog from '../AppointmentDialogs/CustomerSearchByPhoneDialog';
import CreateCustomerDialog from '../AppointmentDialogs/CreateCustomerDialog';
import AppointmentDialog from '../AppointmentDialogs/AppointmentDialog';
import { CalendarCallbackContext } from './CalendarCallbackContext';
import { getCurrentStore } from '../../apis/storesApis'
import WalkInAppointmentDialog from "../AppointmentDialogs/WalkInAppointmentDialog";

const DIALOG_NAMES = {
  customerSearch: "CUSTOMER_SEARCH",
  createCustomer: "CREATE_CUSTOMER",
  appointment: "APPOINTMENT",
}

const INITIAL_APPT_STATE = {
  id: -1,
  title: "",
  description: "",
  start: "",
  end: "",
  customer: -1,
  isCheckedIn: false,
}

const CalendarScheduler = () => {
  const [openDialog, setOpenDialog] = useState(false);
  const [walkInDialogOpen, setWalkInDialogOpen] = useState(false)
  const [isFlashOpen, setIsFlashOpen] = useState(false);
  const [flashMessage, setFlashMessage] = useState("");
  const [customerLookup, setCustomerLookup] = useState({});
  const [newCustomerPhoneNumber, setNewCustomerPhoneNumber] = useState('');
  const { customer, clearCustomer } = useCustomer(customerLookup);

  const [store, setStore] = useState({
    id: '',
    username: '',
    first_name: '',
    last_name: '',
    email: '',
    phone: '',
    membership: '',
    default_appointment_duration: '',
  });

  useEffect(() => {
    fetchStore();
  }, []);

  const fetchStore = async () => {
    // setSpinnerOpen(false);
    try {
      const res = await getCurrentStore();
      const responseStore = res.data;

      setStore({
        id: responseStore.user['id'],
        username: responseStore.user['username'],
        email: responseStore.user['email'],
        first_name: responseStore.user['first_name'],
        last_name: responseStore.user['last_name'],
        phone: responseStore.phone,
        membership: responseStore.membership,
        default_appointment_duration: responseStore.default_appointment_duration,
      });
    } catch (e) {

    } finally {
      // setSpinnerOpen(false);
    }
  };


  const [appointment, setAppointment] = useState(INITIAL_APPT_STATE);
  const {
    appointments,
    loading: appointmentsLoading,
    refreshSingleAppointment,
    refetchWithoutLoadingStatus,
  } = useAppointments();
  const colorCodedEvents = useMemo(
    () =>
      appointments.map((appointment) => {
        const { start, end, title, isCheckedIn } = appointment;
        return {
          start,
          end,
          title,
          backgroundColor: getAppointmentBackgroundColor(isCheckedIn, end),
          extendedProps: { appointment },
        };
      }),
    [appointments]
  );

  const onDateClick = (e) => {
    setOpenDialog(DIALOG_NAMES.customerSearch);
    setAppointment(appt => ({
      ...appt,
      start: formatDate(e.dateStr),
      end: formatDateAddTime(e.dateStr, store.default_appointment_duration),
    }));
  };

  const onDateSelect = (e) => {
    setOpenDialog(DIALOG_NAMES.customerSearch);
    setAppointment(appt => ({
      ...appt,
      start: formatDate(e.startStr),
      end: formatDate(e.endStr),
    }));
  };

  const editAppointment = useCallback((appointment) => {
    setAppointment({
      ...appointment,
      start: formatDate(appointment.start),
      end: formatDate(appointment.end),
    });
    setCustomerLookup({ id: appointment.customer });
    setOpenDialog(DIALOG_NAMES.appointment);
  }, [setAppointment, setCustomerLookup, setOpenDialog]);

  const handleAppointmentChange = (e) => {
    const { name, value } = e.target;
    setAppointment((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleCreateCustomer = async (customer) => {
    try {
      const res = await createCustomer(customer);
      const newCustomer = res.data.customer;
      setAppointment(appt => ({
        ...appt,
        customer: newCustomer.id,
      }));
      setCustomerLookup({ id: newCustomer.id });
      setOpenDialog(DIALOG_NAMES.appointment)
    } catch (e) {
      setIsFlashOpen(true);
      setFlashMessage(e.response.data.error.message);
    }
  };

  const createAppointmentHandler = async () => {
    try {
      const res = await createAppointment(appointment);
      refreshSingleAppointment(res.data.appointment.id);
    } catch (e) {
      setIsFlashOpen(true);
      setFlashMessage(e.response.data);
    }
  };

  const updateAppointmentHandler = useCallback(async (appointment) => {
    try {
      const res = await updateAppointment(appointment);
      refreshSingleAppointment(res.data.appointment.id);
      setAppointment({ ...INITIAL_APPT_STATE });
    } catch (e) {
      // handle error
    }
  }, [refreshSingleAppointment]);

  const deleteAppointmentHandler = useCallback(async (appointmentId) => {
    await deleteAppointment(appointmentId);
    refetchWithoutLoadingStatus();
  }, [refetchWithoutLoadingStatus]);

  const checkInAppointmentHandler = useCallback(async (appointment) => {
    try {
      const res = await bulkUpdateAppointments([appointment.id], true);
      refreshSingleAppointment(res.data.appointments[0].id);
      setAppointment({ ...INITIAL_APPT_STATE });
    } catch (e) {
      // handle error
    }


  }, [refreshSingleAppointment]);

  const unCheckInAppointmentHandler = useCallback(async (appointment) => {
    try {
      const res = await bulkUpdateAppointments([appointment.id], false);
      refreshSingleAppointment(res.data.appointments[0].id);
      setAppointment({ ...INITIAL_APPT_STATE });
    } catch (e) {
      // handle error
    }

  }, [refreshSingleAppointment]);


  const handleSubmit = async () => {
    const submitCallback = appointment.id < 0 ? createAppointmentHandler : updateAppointmentHandler;
    try {
      await submitCallback(appointment);
      setOpenDialog();
    } catch (error) {
      // TODO: error handling
      console.log(error)
    }
  };

  const handleCheckIn = async () => {
    try {
      await checkInAppointmentHandler(appointment);
      setOpenDialog();
    } catch (error) {
      // TODO: error handling
      console.log(error)
    }
  };

  const handleUnCheckIn = async () => {
    try {
      await unCheckInAppointmentHandler(appointment);
      setOpenDialog();
    } catch (error) {
      // TODO: error handling
      console.log(error)
    }
  };

  const handleDelete = async () => {
    try {
      await deleteAppointmentHandler(appointment.id);
      setOpenDialog();
    } catch (error) {
      // TODO: error handling
      console.log(error)
    }
  };



  const renderFlashMessageComponent = (
    <FlashMessage
      message={flashMessage}
      open={isFlashOpen}
      handleClose={() => setIsFlashOpen(false)}
    />
  );

  const onDialogClose = () => {
    setOpenDialog();
    clearCustomer();
    setAppointment(INITIAL_APPT_STATE);
    setNewCustomerPhoneNumber('');
  };

  const onEventDrop = ({ event, oldEvent }) => {
    updateAppointmentHandler({
      ...event.extendedProps.appointment,
      start: formatDate(event.start),
      end: formatDate(event.end),
    });
  };


  const calendarCallbackContext = useMemo(() => ({
    refetchAppointments: refetchWithoutLoadingStatus,
    editAppointment,
    deleteAppointment: deleteAppointmentHandler,
    checkInAppointment: checkInAppointmentHandler,
    unCheckInAppointment: unCheckInAppointmentHandler
  }), [refetchWithoutLoadingStatus, editAppointment, deleteAppointmentHandler, checkInAppointmentHandler, unCheckInAppointmentHandler]);

  const onWalkInClick = () => setWalkInDialogOpen(true);

  return (
    <div>
      <Spinner open={appointmentsLoading} />
      {renderFlashMessageComponent}
      <CalendarCallbackContext.Provider value={calendarCallbackContext}>
        <FullCalendar
          plugins={[
            interactionPlugin,
            dayGridPlugin,
            listPlugin,
            timeGridPlugin,
            multiMonthPlugin,
            customListViewPlugin,
          ]}
          views={{
            timeGridDay: {
              type: 'timeGridDay',
              slotEventOverlap: false,
            },
          }}
          initialView="timeGridDay"
          editable
          height="calc(100vh - 96px)"
          selectable
          nowIndicator
          events={colorCodedEvents}
          headerToolbar={{
            start: "prev,next today addAppointment walkIn", // will normally be on the left. if RTL, will be on the right
            center: "title",
            end: "multiMonthYear,dayGridMonth,timeGridWeek,timeGridDay,custom", // will normally be on the right. if RTL, will be on the left
          }}
          customButtons={{
            addAppointment: {
              text: "New Appointment",
              click: (e) => onDateClick(e),
            },
            walkIn: {
              text: "Walk in",
              click: onWalkInClick,
            }
          }}
          dateClick={onDateClick}
          select={onDateSelect}
          eventDrop={onEventDrop}
          eventClick={(e) => editAppointment(e.event.extendedProps.appointment)}
        />
      </CalendarCallbackContext.Provider>
      <CustomerSearchByPhoneDialog
        open={openDialog === DIALOG_NAMES.customerSearch}
        onClose={onDialogClose}
        onFound={(id) => {
          setAppointment(appt => ({ ...appt, customer: id }))
          setCustomerLookup({ id });
          setOpenDialog(DIALOG_NAMES.appointment);
        }}
        onNotFound={(phone) => {
          setOpenDialog(DIALOG_NAMES.createCustomer);
          setNewCustomerPhoneNumber(phone);
        }}
      />
      <CreateCustomerDialog
        open={openDialog === DIALOG_NAMES.createCustomer}
        onClose={onDialogClose}
        phoneNumber={newCustomerPhoneNumber}
        onCreate={handleCreateCustomer}
      />
      <AppointmentDialog
        open={openDialog === DIALOG_NAMES.appointment}
        appointment={appointment}
        customer={customer}
        onClose={onDialogClose}
        onAppointmentChange={handleAppointmentChange}
        onSubmit={handleSubmit}
        onCheckIn={handleCheckIn}
        onUnCheckIn={handleUnCheckIn}
        onDelete={handleDelete}
      />
      <WalkInAppointmentDialog
        open={walkInDialogOpen}
        onClose={() => setWalkInDialogOpen(false)}
        onSuccess={(appt) => refreshSingleAppointment(appt.id)}
        onError={(msg) => {
          setFlashMessage(msg);
          setIsFlashOpen(true);
        }}
      />
    </div>
  );
};

export default CalendarScheduler;
