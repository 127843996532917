import React, { useEffect, useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField, Box } from '@mui/material';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';

import MessagesTable from '../../components/MessagesTable/MessagesTable';
import Spinner from '../../components/Spinner/Spinner';

import { createMessage, listMessages, updateMessage, deleteMessage, getMessage } from '../../apis/messagesApis'
import { addHyphenToPhoneNumber, updateListItemById } from '../../utils/commonUtil';

const MessagesPage = () => {
  const [messages, setMessages] = useState([]);
  const [spinnerOpen, setSpinnerOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(20);
  const [totalCount, setTotalCount] = useState(0);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [editMessageDialog, setEditMessageDialog] = useState('');

  const [message, setMessage] = useState({
    id: '',
    first_name: '',
    last_name: '',
    phone: '',
    message_body: '',
    send_at: '',
    status:'',
  });

  useEffect(() => {
    fetchPaginatedMessages(currentPage, pageSize);
  }, [currentPage, pageSize]);

  const fetchPaginatedMessages =  async (page = 0, pageSize) => {
    setSpinnerOpen(true);
    try {
      const res = await listMessages(page, pageSize);
      console.log('res');
      
      setMessages([...res.data.messages]);
      setTotalCount(res.data.total);
    } catch(e) {

    } finally {
      setSpinnerOpen(false);
    }
  };

  const handleMessageChange = (e) => {
    const { name, value } = e.target;
    setMessage((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleCreateMessage = async () => {
    setSpinnerOpen(true);
    try {
      const res = await createMessage(message);
      const newMessage = res.data.message;
      const updatedMessages = [...messages, newMessage];
      setMessages(updatedMessages);
    } catch (e) {
      // setIsFlashOpen(true);
      // setFlashMessage(e.response.data.error.message);
    } finally {
      setSpinnerOpen(false);
      setDialogOpen(false);
    }
  };

  const handleMessageRefreshAfterUpdate = async (messageId) => {
    const { data: updatedMessage }  = await getMessage(messageId);

    setMessages(messages => updateListItemById(updatedMessage, messages));
  };


  const handleUpdateMessage = async () => {
    setSpinnerOpen(true);
    try {

      await updateMessage(message);
      handleMessageRefreshAfterUpdate(message.id);
    } catch (e) {
      // setIsFlashOpen(true);
      // setFlashMessage(e.response.data.error.message);
    } finally {
      setSpinnerOpen(false);
      setDialogOpen(false);
    }
  };




  const handleDeleteMessage = async () => {
    setSpinnerOpen(true);
    try {
      const res = await deleteMessage(message);
      const updatedMessages = messages.filter(c => c.id != message.id);
      setMessages(updatedMessages);
    } catch (e) {
      // setIsFlashOpen(true);
      // setFlashMessage(e.response.data.error.message);
    } finally {
      setDialogOpen(false);
      setSpinnerOpen(false);
    }
  };


  const onPageChangeHandler = (page) => {
    setCurrentPage(page);
  };

  const onPageSizeChangeHandler = (pageSize) => {
    setPageSize(pageSize);
  }

  const onMessageCreate = () => {
    setEditMessageDialog('CREATE')
    setDialogOpen(true);
    setMessage({
        id: '',
        first_name: '',
        last_name: '',
        phone: '',
        message_body: '',
        send_at: '',
        status: '',
      });
  };

  const onMessageEdit = (message) => {

    setEditMessageDialog('EDIT');
    setDialogOpen(true);
    setMessage({
      id: message.id,
      first_name: message.first_name,
      last_name: message.last_name,
      phone: message.phone,
      message_body: message.message_body,
      send_at: message.send_at,
      status: message.status,
    });
  };


  const onMessageDelete = (message) => {
    setEditMessageDialog('DELETE');
    setDialogOpen(true);
    setMessage({
      id: message.id,
    });
  };

  const renderDialogContentCreate = (
    <Box component='form' sx={{ m: 2 }}>
      {
        <div>
          <TextField
            label='Phone'
            variant='outlined'
            fullWidth
            margin='normal'
            name='phone'
            value={addHyphenToPhoneNumber(message.phone)}
            onChange={handleMessageChange}
          />
          <TextField
            label='Message Body'
            variant='outlined'
            fullWidth
            margin='normal'
            name='message_body'
            value={message.message_body}
            onChange={handleMessageChange}
          />
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DateTimePicker
              label='Send At'
              value={new Date(message.send_at)}
              onChange={(newValue) => handleMessageChange({ target: { name: 'send_at', value: newValue } })}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant='outlined'
                  fullWidth
                  margin='normal'
                  name='send_at'
                />
              )}
            />
          </LocalizationProvider>
        </div>
      }
    </Box>
  );

  const renderCreateDialogComponent = (
    <Dialog
      open={dialogOpen}
      onClose={() => setDialogOpen(false)}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
      fullWidth={true}
    >
      <DialogTitle id='alert-dialog-title'>Create message</DialogTitle>
      <DialogContent>
        {renderDialogContentCreate}
      </DialogContent>
      <DialogActions>
        <Button color='primary' variant='contained' onClick={handleCreateMessage}> Create</Button>
        <Button color='primary' variant='contained' onClick={() => setDialogOpen(false)}> Cancel</Button>
      </DialogActions>
    </Dialog>
  );


  const renderDialogContentUpdate = (
    <Box component='form' sx={{ m: 2 }}>
      {
        <div>
          <TextField
            label='First Name'
            variant='outlined'
            fullWidth
            margin='normal'
            name='first_name'
            value={message.first_name}
            onChange={handleMessageChange}
            disabled
          />
          <TextField
            label='Last Name'
            variant='outlined'
            fullWidth
            margin='normal'
            name='last_name'
            value={message.last_name}
            onChange={handleMessageChange}
            disabled
          />
          <TextField
            label='Phone'
            variant='outlined'
            fullWidth
            margin='normal'
            name='phone'
            value={addHyphenToPhoneNumber(message.phone)}
            onChange={handleMessageChange}
          />
          <TextField
            label='Message Body'
            variant='outlined'
            fullWidth
            margin='normal'
            name='message_body'
            value={message.message_body}
            onChange={handleMessageChange}
          />
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DateTimePicker
              label='Send At'
              value={new Date(message.send_at)}
              onChange={(newValue) => handleMessageChange({ target: { name: 'send_at', value: newValue } })}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant='outlined'
                  fullWidth
                  margin='normal'
                  name='send_at'
                />
              )}
            />
          </LocalizationProvider>
          <TextField
            disabled
            label='Status'
            variant='outlined'
            fullWidth
            margin='normal'
            name='status'
            value={message.status}
            onChange={handleMessageChange}
          />
        </div>
      }
    </Box>
  );


  const renderUpdateDialogComponent = (
    <Dialog
      open={dialogOpen}
      onClose={() => setDialogOpen(false)}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
      fullWidth={true}
    >
      <DialogTitle id='alert-dialog-title'>Update message</DialogTitle>
      <DialogContent>
        {renderDialogContentUpdate}
      </DialogContent>
      <DialogActions>
        <Button color='primary' variant='contained' onClick={handleUpdateMessage}> Save</Button>
        <Button color='primary' variant='contained' onClick={() => setDialogOpen(false)}> Cancel</Button>
      </DialogActions>
    </Dialog>
  );


  const renderDeleteDialogComponent = (
    <Dialog
      open={dialogOpen}
      onClose={() => setDialogOpen(false)}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
      fullWidth={true}
    >
      <DialogTitle id='alert-dialog-title'>Are you sure you want to delete message</DialogTitle>
      <DialogActions>
        <Button color='primary' variant='contained' onClick={handleDeleteMessage}> Delete</Button>
        <Button color='primary' variant='contained' onClick={() => setDialogOpen(false)}> Cancel</Button>
      </DialogActions>
    </Dialog>
  );

  return (
    <Box p={2}>
      <Spinner open={spinnerOpen} />

      {/* <Grid item xs={true} sm={9}> */}
        <Button style={{ float: 'right' }} color='primary' variant='contained' onClick={onMessageCreate}> Create message</Button>
      {/* </Grid> */}


      <MessagesTable
        rowsPerPage={pageSize}
        totalCount={totalCount}
        messages={messages}
        onPageChange={onPageChangeHandler}
        onPageSizeChange={onPageSizeChangeHandler}
        onMessageEdit={onMessageEdit}
        onMessageDelete={onMessageDelete}
      />
      {
        editMessageDialog === 'CREATE' ? renderCreateDialogComponent
          : editMessageDialog === 'EDIT' ? renderUpdateDialogComponent
            : renderDeleteDialogComponent
      }
     </Box>
   );
};

export default MessagesPage;
