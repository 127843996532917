import { useEffect } from 'react';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Checkbox from '@mui/material/Checkbox';

const CustomListHeader = ({ allEventIds, columns, selectable, selectedEventIds, setSelectedEventIds }) => {
  const indeterminate = selectedEventIds.length > 0 && selectedEventIds.length < allEventIds.length;
  const checked = selectedEventIds.length === allEventIds.length;
  const onCheckBoxChange = (event) => {
    const checked = event.target.checked;
    const selectedIds = checked ? allEventIds : [];
    setSelectedEventIds(selectedIds)
  }
  useEffect(() => {
    setSelectedEventIds([]);
  }, [allEventIds, setSelectedEventIds])
  return (
    <TableRow>
      {selectable && (
        <TableCell padding="checkbox">
          < Checkbox
            checked={checked}
            indeterminate={indeterminate}
            onChange={onCheckBoxChange}
          />
        </TableCell>
      )
      }
      {
        columns.map(column => (
          <TableCell width={column.width} key={column.name}>
            {column.name}
          </TableCell>
        ))
      }
    </TableRow >
  );
};

export default CustomListHeader;