import { useState, useMemo, useContext } from 'react';
import Button from "@mui/material/Button";
import CheckInIcon from '@mui/icons-material/Done';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import CustomListHeader from './CustomListHeader';
import CustomListRow from './CustomListRow';
import CustomListEmptyState from './CustomListEmptyState';
import { formatDate, formatRange } from '@fullcalendar/core'
import CheckInStatusChip from './CheckInStatusChip';
import { bulkUpdateAppointments } from '../../../apis/appointmentApis';
import { CalendarCallbackContext } from '../CalendarCallbackContext';

const DATE_SETTINGS = {
  month: 'long',
  year: 'numeric',
  day: 'numeric',
  weekday: 'long',
  timeZone: 'UTC',
}

const TIME_RANGE_SETTINGS = {
  hour12: true,
  hour: '2-digit',
  minute: '2-digit',
  timeZone: 'UTC',
}

const getColumns = (onEditAppt, onDeleteAppt, onCheckIn) => {
  return [
    {
      name: 'Time',
      getValue: event => formatRange(event.instance.range.start, event.instance.range.end, TIME_RANGE_SETTINGS),
      colspan: 4,
      width: "20%",
    },
    {
      name: 'Status',
      getValue: event => <CheckInStatusChip checkedIn={!!event.def.extendedProps.appointment.isCheckedIn} />,
      width: "10%",
    },
    {
      name: 'Title',
      getValue: event => event.def.title,
      width: "30%",
    },
    {
      name: 'Description',
      getValue: event => event.def.extendedProps.appointment.description,
      width: "30%",
    },
    {
      name: 'Actions',
      getValue: event => (
        <Stack direction="row">
          <IconButton onClick={() => onEditAppt(event.def.extendedProps.appointment)}>
            <EditIcon />
          </IconButton>
          <IconButton onClick={() => onDeleteAppt(event.def.extendedProps.appointment.id)}>
            <DeleteIcon />
          </IconButton>
          <IconButton onClick={() => onCheckIn(event.def.extendedProps.appointment)}>
            <CheckInIcon />
          </IconButton>
        </Stack>
      ),
      width: "20%",
    }
  ]
}


const CustomList = ({ events, selectable, dateProfile }) => {
  const [selectedEventIds, setSelectedEventIds] = useState([]);
  const hasSelectedEvents = selectedEventIds.length > 0;
  const dateTitle = formatDate(dateProfile.currentRange.start, DATE_SETTINGS);
  const allEventIds = useMemo(() => events.map(e => e.def.extendedProps.appointment.id), [events]);
  const sortedEvents = useMemo(() => {
    const sorted = [...events];
    sorted.sort((a, b) => a.instance.range.start - b.instance.range.start);
    return sorted
  });
  const { refetchAppointments, editAppointment, deleteAppointment, checkInAppointment } = useContext(CalendarCallbackContext);
  const getBulkUpdateCallback = (isCheckedIn) => async () => {
    try {
      await bulkUpdateAppointments(selectedEventIds, isCheckedIn);
      refetchAppointments();
      setSelectedEventIds([]);
    } catch (e) {
      // TODO: error handling
    } finally {
      // TODO: handle final state
    }
  };

  const columns = useMemo(() => {
    return getColumns(editAppointment, deleteAppointment, checkInAppointment)
  }, [editAppointment, deleteAppointment, checkInAppointment])
  return (
    <>
      <Stack direction="row" sx={{ justifyContent: "space-between", paddingBottom: "1rem" }}>
        <Typography variant='h4'>
          {dateTitle}
        </Typography>
        <Stack direction="row" width={320} sx={{ justifyContent: "space-between" }}>
          <Button
            color="success"
            variant="contained"
            onClick={getBulkUpdateCallback(true)}
            disabled={!hasSelectedEvents}
          >
            Mark as checked in
          </Button>
          <Button
            color="error"
            variant="contained"
            onClick={getBulkUpdateCallback(false)}
            disabled={!hasSelectedEvents}
          >
            Mark as pending
          </Button>
        </Stack>
      </Stack>
      <TableContainer className="appointment-custom-list" component={Paper}>
        <Table size="small">
          <TableHead
            sx={{
              '& .MuiTableRow-root': {  // Targets the TableRow within the TableHead
                backgroundColor: '#354B5E',
              },
              '& .MuiTableCell-head': {  // Optionally, target individual cells if needed
                color: 'white',  // This will make the text color white for better contrast
              }
            }}
          >
            <CustomListHeader
              allEventIds={allEventIds}
              columns={columns}
              selectable={selectable}
              selectedEventIds={selectedEventIds}
              setSelectedEventIds={setSelectedEventIds}
            />
          </TableHead>
          <TableBody sx={{
            '& .MuiTableRow-root:nth-of-type(odd)': {
              backgroundColor: 'white',
            },
            '& .MuiTableRow-root:nth-of-type(even)': {
              backgroundColor: '#F5F5F5',
            },
          }}>
            {sortedEvents.map(event => (
              <CustomListRow
                key={event.def.extendedProps.appointment.id}
                event={event}
                columns={columns}
                selectable={selectable}
                selectedEventIds={selectedEventIds}
                setSelectedEventIds={setSelectedEventIds}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {events.length === 0 && <CustomListEmptyState />}
    </>
  );
};

export default CustomList;